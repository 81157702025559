import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { LocationDto } from '../model/location-dto';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  url = `${environment.api}consult-locations`;

  constructor(protected httpClient: HttpClient) { }

  listAll(filter: string): Observable<LocationDto[]> {
    const params = new HttpParams()
      .set('searchKey', filter);
    return this.httpClient.get<LocationDto[]>(`${this.url}`, { params }).pipe(
      catchError(() => {
        return of([]);
      })
    );
  }
}
