<div class="dialog">
    <div *ngIf="isLoading" class="loading-overlay">
        <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="!isLoading">
        <div class="row">
            <h2 class="titulo">Reenviar mensaje</h2>
        </div>
        
        <div class="row">
            <div style="display: flex; flex-direction: column;">
                <label class="title">Error</label>
                <textarea type="text" class="error-area" readonly>{{mensajeDto.description}}</textarea>
            </div>
        </div>
    </div>

    <div *ngIf="!isLoading" class="json-area-container-row">
        <div class="json-area-container-col">
            <div style="width: 100%; height: 100%;">
                <label class="title">Mensaje original</label>
                <textarea type="text" class="json-area" readonly>{{mensajeDto.jsonMain}}</textarea>
            </div>
        </div>

        <div class="json-area-container-col">
            <div style="width: 100%; height: 100%;">
                <label class="title">Último reintento</label>
                <textarea type="text" class="json-area" [(ngModel)]="mensajeDto.jsonRetry">{{mensajeDto.jsonRetry}}</textarea>
            </div>
        </div>
    </div>

    <div *ngIf="!isLoading">
        <button mat-raised-button class="discard-button" type="submit" (click)="discard()">
            Descartar mensaje
        </button>
        <button mat-raised-button color="primary" type="submit" class="retry-button" (click)="submit()" 
            [disabled]="mensajeDto.jsonRetry == undefined || mensajeDto.jsonRetry == ''">
            Reenviar mensaje
        </button>
    </div>
</div>