export class UserDto {

    identificationType: string;
    identificationNumber: string;
    firstName: string;
    secondName: string;
    firstLastName: string;
    secondLastName: string;
    username: string;

    constructor(identificationType: string, identificationNumber: string,
        firstName: string, secondName: string,
        firstLastName: string, secondLastName: string,
        username: string) {
            this.identificationType = identificationType;
            this.identificationNumber = identificationNumber;
            this.firstName = firstName;
            this.secondName = secondName;
            this.firstLastName = firstLastName;
            this.secondLastName = secondLastName;
            this.username = username;
    }
}
