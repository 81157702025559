export class MedicalAttentionsFilterDto{
    filterType: string;
    identification: string;
    patient?: string;
    ips?: string;
    page: number;
    size: number;
    initDate: string;
    endDate: string;

    constructor(filterType: string, identification: string, patient: string, ips: string, 
        page: number, size: number, initDate: string, endDate: string) {
        this.filterType = filterType;
        this.identification = identification;
        this.patient = patient;
        this.ips = ips;
        this.page = page;
        this.size = size;
        this.initDate = initDate;
        this.endDate = endDate;
    }
}
