import { Component, Inject, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ShareService } from '../../../../shared/service/share-service.service';
import { OrderServiceRequestDetailService } from '../../shared/service/order-service-request-detail.service';
import { OrderServiceRequestDetailInfoDto } from '../../shared/model/order-service-request-detail-info-dto';
import { ManagedOrderServiceRequestService } from '../../shared/service/managed-order-service-request.service';
import { FinalizeManagedOrderServiceRequestService } from '../../shared/service/finalize-managed-order-service-request.service';
import { AlertConfirmComponent, AlertConfirmDataDTO } from '../../../alert-confirm/alert-confirm.component';

class FinalizeOrderServiceRequest {
  idFinalize: number;
  groupName: string;
  datasource: MatTableDataSource<OrderServiceRequestDetailInfoDto>;
  canFinalize: boolean;
  canMarkAsProcessed: boolean;
}

@Component({
  selector: 'app-request-details',
  templateUrl: './order-service-request-detail.component.html',
  styleUrls: ['./order-service-request-detail.component.scss']
})
export class OrderServiceRequestDetailComponent implements OnInit {

  orderServiceRequestDetailDS: FinalizeOrderServiceRequest[] = []; 
  transactionCode: number;
  patient: string;
  ips: string;
  dateAttention: string
  idFinalize: number;
  idsToFinalize: number[] = [];
  displayedColumns: string[] = ['code', 'name', 'status', 'action'];
  isLoading = false;
  mustReloadAfterClose = false;
  showNoRecordsToViewMessage = false;
  
  APPROVED_STATUD = "APROBADO";
  PENDING_VALIDATION_STATUS = "PENDIENTE VALIDACION";
  REJECTED_STATUS = "RECHAZADO";
  MANAGED_STATUS = "GESTIONADO";

  constructor(public orderServiceRequestDetailDialogRef: MatDialogRef<OrderServiceRequestDetailComponent>,
    protected detailService: OrderServiceRequestDetailService, 
    protected shareService: ShareService,
    protected manageService: ManagedOrderServiceRequestService, 
    protected finalizeService: FinalizeManagedOrderServiceRequestService,
    private readonly _snackBar: MatSnackBar,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      this.transactionCode = data.transactionCode;
      this.patient = data.patient;
      this.ips = data.ips;
      this.dateAttention = data.dateAttention
    }

  ngOnInit() {
    this.loadData();
  }

  loadData() : void {
    this.isLoading = true;
    this.detailService.listAll(this.transactionCode).subscribe((data) => {
      this.isLoading = false;
      data = data.filter(item => item.idFinalize !== this.idFinalize);
      if(data !== undefined && data.length > 0) {
        this.processDataSource(data);
      } else {
        this.mustReloadAfterClose = true;
        this.openSnackBar("No existen prestaciones pendientes por gestionar para esta atención.");
        this.closeDetail();
      }
    });
  }

  markAsManaged(id: number, idFinalize: number) : void {
    this.isLoading = true;
    this.manageService.markAsManaged(id).subscribe({
      next: value => this.handleManageSuccess(id, idFinalize),
      error: error => {
        this.handleResponseError('Error al tratar de marcar la prestación como GESTIONADA');
      }
    });
  }

  handleManageSuccess(id: number, idFinalize: number) : void {
    this.isLoading = false;
    const message = 'Prestación marcada como GESTIONADA correctamente';
    this.openSnackBar(message);
    this.mustReloadAfterClose = true;

    let mustSendToMarkAsProcessed = false;
    const foundGroup = this.orderServiceRequestDetailDS.find((element) => element.idFinalize === idFinalize);
    if(foundGroup !== undefined) {
      let allManaged = true;
      foundGroup.datasource.data.forEach((row) => {
        if(row.id !== id && row.status !== this.MANAGED_STATUS) {
          allManaged = false;
        }
      });
      mustSendToMarkAsProcessed = allManaged;
    }

    if(mustSendToMarkAsProcessed) {
      this.markAsProcessed(idFinalize);
    } else {
      this.loadData();
    }
  }

  handleResponseError(message : string) : void {
    this.isLoading = false;
    this.openSnackBar(message);
    this.mustReloadAfterClose = true;
  }

  finalizeGroup(idFinalize: number) : void {
    this.isLoading = true;
    this.idFinalize = idFinalize;
    this.finalizeService.finalizeManaged(idFinalize).subscribe({
      next: value => { 
        this.handleResponseSuccess('Solicitud de finalización ejecutada correctamente'); 
      },
      error: error => {
        this.handleResponseError('Error al tratar de realizar la solicitud de finalización del grupo de prestaciones');
      }
    });
  }

  handleResponseSuccess(message: string) : void {
    this.isLoading = false;
    this.openSnackBar(message);
    this.mustReloadAfterClose = true;
    this.loadData();
  }

  openSnackBar(message: string) : void {
    this._snackBar.open(message, 'Cerrar', {
      duration: 5000
    });
  }

  processDataSource(data: OrderServiceRequestDetailInfoDto[]): void {
    this.orderServiceRequestDetailDS = []; 
    data.forEach((row) => {
      const foundGroup = this.orderServiceRequestDetailDS.find((element) => element.idFinalize === row.idFinalize);
      if(foundGroup === undefined) {
        const newDs = new MatTableDataSource<OrderServiceRequestDetailInfoDto>();
        newDs.data = [row];

        const newGroup: FinalizeOrderServiceRequest = {
          idFinalize: row.idFinalize,
          groupName: this.getGroupName(Number(row.type), row.businessProcess),
          datasource: newDs,
          canFinalize: false,
          canMarkAsProcessed: false
        }

        this.orderServiceRequestDetailDS.push(newGroup);
      } else {
        foundGroup.datasource.data.push(row);
      }
    });

    this.orderServiceRequestDetailDS.forEach((element) => {
      const hasApproved = element.datasource.data.find((row) => row.status === this.APPROVED_STATUD);
      const hasRejected = element.datasource.data.find((row) => row.status === this.REJECTED_STATUS);
      const hasPending = element.datasource.data.find((row) => row.status === this.PENDING_VALIDATION_STATUS);
      element.canFinalize = hasApproved !== undefined && hasRejected === undefined && hasPending === undefined;
      element.canMarkAsProcessed = hasApproved === undefined && hasRejected === undefined && hasPending === undefined;
    });

    if(this.orderServiceRequestDetailDS.length === 0) {
      this.showNoRecordsToViewMessage = true;
    }
  }

  getGroupName(orderType: number, businessProcess: string) : string {
    if(businessProcess.includes("SAS")) {
      return businessProcess;
    } else {
      return `${businessProcess} ${this.getOrderTypeDescription(orderType)}`;
    }
  }

  getOrderTypeDescription(orderType: number) : string {
    if(orderType === 1) { 
      return "(Medicamentos)"; 
    } else if(orderType === 2) { 
      return "(Ayudas diagnósticas)"; 
    } else if(orderType === 3) { 
      return "(Remisiones)"; 
    } else { 
      return ""; 
    }
  }

  showRejectedMessage(element : OrderServiceRequestDetailInfoDto) : void {
    const alertData = new AlertConfirmDataDTO(false, true, "Motivo de rechazo", element.rejectedMessage);
    this.dialog.open(AlertConfirmComponent, { data: alertData });
  }

  closeDetail() : void {
    const signalToSend = this.mustReloadAfterClose ? "reload" : "ok";
    this.orderServiceRequestDetailDialogRef.close(signalToSend);
  }

  isRejectedOrManaged(status : string) : boolean {
    return status === this.REJECTED_STATUS || status === this.MANAGED_STATUS;
  }

  markAsProcessed(idFinalize: number) : void {
    this.isLoading = true;
    this.finalizeService.markAsProcessed(idFinalize).subscribe({
      next: value => {
        this.handleResponseSuccess('Solicitud PROCESADA correctamente');
      },
      error: error => {
        this.handleResponseError('Error al tratar de marcar la solicitud del grupo de prestaciones como PROCESADA')
      }
    });
  }
}
