import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { UserInfoDto } from '../model/user-info-dto';
import { environment } from '../../../../../environments/environment';
import { RoleDto } from '../model/role-dto';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  url = `${environment.api}`;

  constructor(private readonly httpClient: HttpClient) { }

  createOne(user: UserInfoDto): Observable<any> {
    return this.httpClient.post(`${this.url}provision-user`, user).pipe(
      catchError((error: any) => {
          const errorMessage = error.error.message || 'Ocurrió un error desconocido';
          return throwError(() => new Error(errorMessage));
      })
  );  }

  createMassive(file: FormData): Observable<any> {
    return this.httpClient.post(`${this.url}provision-massive-user`, file, { responseType: 'blob' });
  }

  search(identificationType: string, identificationNumber: string): Observable<UserInfoDto> {
    const params = new HttpParams()
      .set('identificationType', identificationType)
      .set('identificationNumber', identificationNumber);
      return this.httpClient.get<UserInfoDto>(`${this.url}consult-user`, { params });
  }

  listRoles() : Observable<RoleDto[]> {
    return this.httpClient.get<RoleDto[]>(`${this.url}list-roles`);
  }
}
