<div class="layout">
  <mat-toolbar color="primary">
    <button mat-icon-button (click)="changeSidebarStatus()" [ngClass]="{ 'header-menu-active': menuActive }">
      <mat-icon>menu</mat-icon>
    </button>
    
    <h2>Hola {{ user?.fullName }}</h2>
    <span class="example-spacer"></span>
    <div>
      <span class="topbar-last-access">Último ingreso: {{ user?.lastAccess }}</span>
      <button mat-icon-button [matMenuTriggerFor]="userMenu">
        <mat-icon>account_circle</mat-icon>
      </button>
      <mat-menu #userMenu="matMenu">
        <button mat-menu-item (click)="logout()">
          <mat-icon>power_off</mat-icon>
          <span>Cerrar Sesión</span>
        </button>
      </mat-menu>
    </div>
  </mat-toolbar>
</div>