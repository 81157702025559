<div class="container">
    <mat-form-field appearance="outline" floatLabel="always" style="width: max-content;">
        <button mat-raised-button matPrefix (click)="fileInput.click()" style="margin: 0 .5em; color: #0033A0;" color="accent">
            <mat-icon>attach_file</mat-icon>
            Seleccionar archivo
        </button>
        <input type="text" readonly matInput [(ngModel)]="selectedFileName" placeholder="Sin archivos seleccionados"/>
        <input hidden accept=".csv" (change)="onFileSelected($event)" #fileInput
            type="file">
    </mat-form-field>

    <div class="link-button-container">
        <button mat-button (click)="onSend()">Enviar</button>
        <button mat-button (click)="onClear()">Limpiar</button>
        <button mat-button (click)="onDownloadSelectedFile()">Descargar plantilla</button>
    </div>

    <div class="table-container" style="margin-top: 20px;">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> ARCHIVO </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <ng-container matColumnDef="result">
                <th mat-header-cell *matHeaderCellDef> RESULTADO </th>
                <td mat-cell *matCellDef="let element"> {{element.result}} </td>
            </ng-container>

            <ng-container matColumnDef="progress">
                <th mat-header-cell *matHeaderCellDef> PROGRESO </th>
                <td mat-cell *matCellDef="let element">
                    <mat-progress-bar mode="determinate" [value]="element.progress"></mat-progress-bar>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</div>