import { Component, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConsumerMessageErrorDto } from '@components/technical-support/shared/model/consumer-message-error-dto';
import { PagingVariables } from '@components/dashboard/shared/global-variables/paging-variables';
import { ConsumerMessageErrorDetailComponent } from '../consumer-message-error-detail/consumer-message-error-detail.component';
import { ConsumerMessageErrorService } from '@components/technical-support/shared/service/consumer-message-error.service';

@Component({
  selector: 'app-consumer-message-error-list',
  templateUrl: './consumer-message-error-list.component.html',
  styleUrls: ['./consumer-message-error-list.component.scss']
})
export class ConsumerMessageErrorListComponent implements OnInit, OnChanges {
  isLoading = true;
  isEmpty = false;
  displayedColumns: string[] = ['id', 'creationDate', 'processType', 'status', 'retryDate', 'retryUser', 'details'];
  datasource = new MatTableDataSource<ConsumerMessageErrorDto>();
  dialogRef: MatDialogRef<ConsumerMessageErrorDetailComponent>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(protected service: ConsumerMessageErrorService, 
    public pagingVariables: PagingVariables,
    public dialog: MatDialog
  ) { }
    
  ngOnInit() {
   this.listAll();
  }
  
  ngOnChanges(changes: SimpleChanges) {
    this.resetPagination();
    this.isLoading = true;
  }

  openDialog(id: number) {
    const dialogRef = this.dialog.open(ConsumerMessageErrorDetailComponent, { data: {id}, width: '100%', height: '80%'});

    dialogRef.afterClosed().subscribe(result => {
      if(result === 'ok') {
        this.listAll();
      }
    });
  }

  changePage(e: any) {
    this.pagingVariables.pageIndex = e.pageIndex;
    this.pagingVariables.pageSize = e.pageSize;
    this.listAll();
  }

  resetPagination() {
    const defaultPageSize = 20;
    this.pagingVariables.pageIndex = 0;
    this.pagingVariables.pageSize = defaultPageSize;

    if (this.paginator) {
      this.paginator.pageIndex = 0;
      this.paginator.pageSize = defaultPageSize;
    }
  }

  listAll() {
    this.datasource.data = [];
    this.service.listConsumerMessageError(this.pagingVariables.pageIndex, this.pagingVariables.pageSize).subscribe(data => {
      if (data.consumerMessageErrors) {
        this.datasource.data = data.consumerMessageErrors.map((item: any) => this.buildMessageDto(item));
      } else {
        this.datasource.data = [];
      }
      this.pagingVariables.quantity = data.totalRecords;
      this.isLoading = false;
      this.isEmpty = this.datasource.data.length === 0 ;
    });
  }

  buildMessageDto(item: any): ConsumerMessageErrorDto {
    return new ConsumerMessageErrorDto(
      item.id,
      item.creationDate,
      item.processType,
      item.status,
      item.retryDate,
      item.retryUser
    );
  }
}
