<form [formGroup]="myForm" (ngSubmit)="submit()" class="form-container" style="margin-top: 2em;">
    <div class="order-field-container">
        <mat-form-field appearance="outline" floatLabel="always" class="wide-form-field">
            <mat-label>Número de orden</mat-label>
            <input matInput placeholder="Digite el número de orden a buscar" aria-label="Digite el número de orden a buscar" 
                formControlName="transactionCode" required >
            <mat-error *ngIf="myForm.get('transactionCode')?.hasError('required')">
                Este campo es obligatorio.
            </mat-error>
        </mat-form-field>        
    </div>

    <div class="submit-button-container">
        <button mat-raised-button color="primary" type="submit">Consultar</button>
    </div>
</form>

<div *ngIf="isLoading" class="loading-overlay">
    <mat-spinner></mat-spinner>
</div>

<table mat-table [dataSource]="datasource">
    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef class="custom-header-cell" style="color: white;">ID</th>
        <td mat-cell *matCellDef="let element" class="custom-cell"> {{element.id}} </td>
    </ng-container>

    <ng-container matColumnDef="receptionDate">
        <th mat-header-cell *matHeaderCellDef class="custom-header-cell" style="color: white;">FECHA DE RECEPCIÓN</th>
        <td mat-cell *matCellDef="let element" class="custom-cell">{{element.receptionDate}}</td>
    </ng-container>

    <ng-container matColumnDef="managementDate">
        <th mat-header-cell *matHeaderCellDef class="custom-header-cell" style="color: white;">FECHA DE ACTUALIZACIÓN</th>
        <td mat-cell *matCellDef="let element" class="custom-cell">{{element.managementDate}}</td>
    </ng-container>

    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef class="custom-header-cell" style="color: white;">ESTADO</th>
        <td mat-cell *matCellDef="let element" class="custom-cell">{{element.status}}</td>
    </ng-container>

    <ng-container matColumnDef="transactionCode">
        <th mat-header-cell *matHeaderCellDef class="custom-header-cell" style="color: white;">ORDEN</th>
        <td mat-cell *matCellDef="let element" class="custom-cell">{{element.transactionCode}}</td>
    </ng-container>

    <ng-container matColumnDef="businessProcess">
        <th mat-header-cell *matHeaderCellDef class="custom-header-cell" style="color: white;">PROCESO DE NEGOCIO</th>
        <td mat-cell *matCellDef="let element" class="custom-cell">{{element.businessProcess}}</td>
    </ng-container>

    <ng-container matColumnDef="generatingInstitution">
        <th mat-header-cell *matHeaderCellDef class="custom-header-cell" style="color: white;">IPS</th>
        <td mat-cell *matCellDef="let element" class="custom-cell">{{element.generatingInstitution}}</td>
    </ng-container>

    <ng-container matColumnDef="requestingUserIdentificationNumber">
        <th mat-header-cell *matHeaderCellDef class="custom-header-cell" style="color: white;">PROFESIONAL</th>
        <td mat-cell *matCellDef="let element" class="custom-cell">{{element.requestingUserIdentificationNumber}}</td>
    </ng-container>

    <ng-container matColumnDef="patientIdentificationNumber">
        <th mat-header-cell *matHeaderCellDef class="custom-header-cell" style="color: white;">PACIENTE</th>
        <td mat-cell *matCellDef="let element" class="custom-cell">{{element.patientIdentificationNumber}}</td>
    </ng-container>

    <ng-container matColumnDef="orderType">
        <th mat-header-cell *matHeaderCellDef class="custom-header-cell" style="color: white;">TIPO ORDEN</th>
        <td mat-cell *matCellDef="let element" class="custom-cell">{{element.orderType}}</td>
    </ng-container>

    <ng-container matColumnDef="details">
        <th mat-header-cell *matHeaderCellDef class="custom-header-cell" style="color: white;">OPCIONES</th>
        <td mat-cell *matCellDef="let element" class="custom-cell">
            <div>
                <button mat-button color="primary" (click)="findJsonRequest(element.id)" matTooltip="Ver request de la solicitud">
                    <mat-icon>description</mat-icon>
                </button>
    
                <ng-container *ngIf="element.hasResponse">
                    <button mat-button color="accent" (click)="findJsonResponse(element.id)" matTooltip="Ver respuesta de SaludWeb">
                        <mat-icon>description</mat-icon>
                    </button>
                </ng-container>
            </div>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>