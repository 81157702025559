import { Routes } from '@angular/router';
import { Error403Component } from '../shared/global-error-components';
import { AuthorizationGuard } from '../core/security';
import { UserFormComponent } from '../layout/user-management/components/user-form/user-form.component';
import { MassiveUserFormComponent } from '../layout/user-management/components/massive-user-form/massive-user-form.component';
import { LayoutComponent } from '../layout/layout.component';
import { MedicalAttentionFilters } from '../layout/dashboard/components/medical-attention-filters/medical-attention-filters.component';
import { MainPageComponent } from '@components/main-page/main-page.component';
import { TechnicalSupportMainViewComponent } from '@components/technical-support/components/technical-support-main-view/technical-support-main-view.component';

export const APP_ROUTES: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        component: MainPageComponent
      },
      {
        path: 'dashboard',
        component: MedicalAttentionFilters, 
        canActivate: [AuthorizationGuard]
      },
      {
        path: 'user-form',
        component: UserFormComponent, 
        canActivate: [AuthorizationGuard]
      },
      {
        path: 'massive-user-form',
        component: MassiveUserFormComponent,
        canActivate: [AuthorizationGuard]
      },
      {
        path: 'technical-support',
        component: TechnicalSupportMainViewComponent,
        canActivate: [AuthorizationGuard]
      }
    ]
  },
  // errors
  { path: 'access-denied', component: Error403Component },

  // Not found
  { path: '**', redirectTo: '' }
];
