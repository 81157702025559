import { LocationDto } from "./location-dto";
import { RoleDto } from "./role-dto";
import { UserDto } from "./user-dto";

export class UserInfoDto {
    user: UserDto;
    userRol: RoleDto[] | string[];
    userLocations: LocationDto[] | string[];

    constructor(user: UserDto, userRol: RoleDto[] | string[], userLocations: LocationDto[] | string[]) {
        this.user = user;
        this.userRol = userRol;
        this.userLocations = userLocations;
    }
}
