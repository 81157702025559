import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpHeaders
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ShareService } from '../../shared/service/share-service.service';

/**
 * HTTP interceptor whose responsibility is to control the global authorization and authentication of requests to the backend. */
@Injectable({
  providedIn: 'root'
})
export class HttpInterceptRequestService implements HttpInterceptor {
  /**
   * Class constructor
   *
   * @param router object to control the routing of the application
   */
  constructor(private readonly router: Router, private readonly location: Location, private readonly dialog: MatDialog,
    private readonly _snackBar: MatSnackBar, private readonly shareService: ShareService) { }

  /**
   * intercepts requests
   *
   * @param req request
   * @param next handler to execute the logic when the request is captured
   */

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    let headers = new HttpHeaders({ 'X-APP-RELAYSTATE': this.location.path() });
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append('Access-Control-Allow-Methods', 'GET,HEAD,OPTIONS,POST,PUT,DELETE');
    const reqWithHeaders = req.clone({ withCredentials: true, headers });
    const errorMessage = 'Ocurrió un error en el servidor, por favor validar con los responsables del aplicativo';

    return next.handle(reqWithHeaders).pipe(
      tap({
        next: (response) => {
        },
        error: (err) => {
          switch (err.status) {
            case 401:
              console.log('No tiene acceso');
              document.write(err.error);
              break;
            case 403:
              console.log("El usuario no está autorizado");
              this.router.navigate(['/access-denied']);
              break;
            case 500:
              this.shareService.currentMessage.pipe(
                map((message: string) => message || errorMessage),
                tap((message: string) => {
                  this._snackBar.open(message, 'Cerrar', {
                    duration: 10000
                  });
                })
              ).subscribe(); 
              this.dialog.closeAll();
              break;
          }
        }
      }),
      catchError((err) => {
        return throwError(() => err);
      })
    );

  }
}
