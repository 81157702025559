import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { UserService } from '../../shared/service/user.service';
import { MassiveLoadResultDto } from '../../shared/model/massive-load-result';


@Component({
  selector: 'app-massive-user-form',
  templateUrl: './massive-user-form.component.html',
  styleUrls: ['./massive-user-form.component.scss']
})
export class MassiveUserFormComponent {

  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  selectedFileName = '';
  dataSource = new MatTableDataSource<MassiveLoadResultDto>();
  displayedColumns: string[] = ['name', 'result'];
  blob: Blob;

  constructor(private readonly userService: UserService, private readonly snackBar: MatSnackBar) { }

  onFileSelected(event: any) {
    const element = event.currentTarget as HTMLInputElement;
    const fileList: FileList | null = element.files;
    if (fileList && fileList.length > 0) {
      if (fileList.item(0)?.type === 'text/csv') {
        const file = fileList[0];
        this.selectedFileName = file.name;
        this.dataSource.data = [
          { name: this.selectedFileName, result: 'Pendiente de envio' }
        ];
      } else {
        this.snackBar.open('El formato seleccionado no es válido', 'Cerrar', { duration: 5000 });
      }
    }
  }

  onSend() {
    const files = this.fileInput.nativeElement.files;
    if (files && files.length > 0) {
      const file = files[0];

      this.dataSource.data = [
        { name: this.selectedFileName, result: 'Procesando' }
      ];

      const formData = new FormData();
      formData.append('file', file, 'file.csv');

      this.userService.createMassive(formData).subscribe({
        next: (response) => {
          this.blob = new Blob([
            response
          ],
            {
              type: 'text/csv'
            }
          );
          this.createDownload(this.blob, 'result.csv');
          this.dataSource.data = [
            { name: this.selectedFileName, result: 'Ok' }
          ];
          this.snackBar.open('Archivo enviado con éxito', 'Cerrar', { duration: 5000 });
        },
        error: (error) => {
          this.dataSource.data = [
            { name: this.selectedFileName, result: 'Fallo' }
          ];
          this.snackBar.open('Error al enviar el archivo, el servicio falló', 'Cerrar', { duration: 5000 });
        }
      });
    } else {
      this.snackBar.open('No hay un archivo seleccionado para enviar.', 'Cerrar', { duration: 5000 });
    }
  }

  onClear() {
    this.fileInput.nativeElement.value = '';
    this.selectedFileName = 'Sin archivo seleccionado';
    this.dataSource.data = [];
    this.snackBar.open('Selección de archivo limpiada', 'Cerrar', { duration: 5000 });
  }

  onDownloadSelectedFile() {
    const columns = ['*TIPO DOCUMENTO', '*NUMERO DOCUMENTO', '*PRIMER NOMBRE', 'SEGUNDO NOMBRE',
      '*PRIMER APELLIDO', '*SEGUNDO APELLIDO', '*USERNAME', '*ROL', '*SEDES'];
    const rows = [[]];
    const csvContent = this.csvMaker(columns, rows);
    this.blob = new Blob([
      csvContent
    ], 
    {
      type: 'text/csv'
    });
    this.createDownload(this.blob, 'template.csv');
  }

  createDownload(file: any, name?: string) {
    const fileURL = window.URL.createObjectURL(file);

    const downloadLink = document.createElement('a');
    downloadLink.href = fileURL;
    downloadLink.download = name || file.name;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    window.URL.revokeObjectURL(fileURL);
  }

  csvMaker(columns: string[], rows: string[][]) {
    const csvRows = [columns.join(',')];

    rows.forEach(row => {
      csvRows.push(row.join(','));
    });

    return csvRows.join('\n');
  }


}
