import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IdentityService } from '@src/app/core/security';

@Injectable({
  providedIn: 'root'
})
export class RedirectService {

  constructor(private readonly identityService: IdentityService, private readonly router: Router) {}

  redirectToFirstAccessiblePage(): void {
    this.identityService.getUserMenu().then(menu => {
      if (menu && menu.menuItems.length > 0) {
        const firstAccessiblePath = menu.menuItems[0].contextPath; 
        this.router.navigate([firstAccessiblePath], { skipLocationChange: true });
      } else {
        this.router.navigate(['']);
      }
    }).catch(() => {
      this.router.navigate(['']);
    });
  }
}
