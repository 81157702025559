import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Observable, startWith, switchMap } from 'rxjs';
import { OptionsService } from '../../shared/service/options.service';
import { inOptionsValidator } from 'src/app/shared/function/in-options-validator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserDto } from '../../shared/model/user-dto';
import { UserService } from '../../shared/service/user.service';
import { UserInfoDto } from '../../shared/model/user-info-dto';
import { ValidationUserFormService } from '../../shared/service/services-logic/validation-user-form.service';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit {
  myForm: FormGroup;
  myControlLocations = new FormControl('');
  myControlRoles = new FormControl('');

  filteredOptionsLocations: Observable<string[]>;
  filteredOptionsRoles: Observable<string[]>;
  filteredOptionsDocumentTypes: Observable<String[]>;

  locations: string[] = [];
  roles: string[] = [];

  userInfoDto: UserInfoDto;

  private identificationType: string;
  private identificationNumber: string;

  private autofillingForm = false;
  isUpdatingUser = false;

  constructor(private readonly optionsService: OptionsService, private readonly _snackBar: MatSnackBar,
    private readonly userService: UserService, public validationUserFormService: ValidationUserFormService,
    private readonly formBuilder: FormBuilder) { }

  ngOnInit() {
    this.initializeForm();
    this.setupFilteredOptions();
    this.onDocumentTypeChange();
  }

  /*
  * generates all input validations
  */

  initializeForm(): void {
    this.myForm = this.formBuilder.group({
      identificationType: this.createControlWithAsyncValidator('', 'documentTypes'),
      firstName: ['', Validators.required],
      firstLastName: ['', Validators.required],
      username: ['', Validators.required],
      identificationNumber: ['', Validators.required],
      secondName: [''],
      secondLastName: ['', Validators.required],
      roles: new FormArray([]),
      locations: new FormArray([])
    });
  }

  private createControlWithAsyncValidator(
    defaultValue: string,
    optionType: 'documentTypes' | 'roles' | 'locations'
  ): FormControl {
    return this.formBuilder.control(defaultValue, {
      validators: [Validators.required],
      asyncValidators: [inOptionsValidator(this.optionsService, optionType)],
      updateOn: 'change'
    });
  }

  /*
  * is responsible for adding a location, if it complies with the validations.
  */

  addALocation(event: Event) {
    event.preventDefault();

    const location = this.myControlLocations.value;
    if (!this.validationUserFormService.isValidLocation(location, this.locationsFormArray)) {
      this.setControlLocationError();
      return;
    }

    this.addLocationToFormArray(location);
    if (location) {
      this.addLocationCode(location);
    }
    this.resetControlLocation();
  }

  addRol(event: Event) {
    event.preventDefault();

    const rol = this.myControlRoles.value;    
    if (!this.validationUserFormService.isValidRol(rol, this.rolesFormArray)) {
      this.setControlRolError();
      return;
    }

    this.addRolToFormArray(rol);
    if (rol) {
      this.addRolCode(rol);
    }
    this.resetControlRol();
  }

  setControlLocationError(): void {
    this.myControlLocations.setErrors({ 'invalidLocation': true });
  }

  setControlRolError(): void {
    this.myControlRoles.setErrors({ 'invalidRol': true });
  }

  addLocationToFormArray(location: string | null): void {
    this.locationsFormArray.push(new FormControl(location, Validators.required));
  }

  addRolToFormArray(rol: string | null): void {
    this.rolesFormArray.push(new FormControl(rol, Validators.required))
  }

  addLocationCode(location: string): void {
    const locationCode = this.validationUserFormService.extractLocationCode(location);
    if (locationCode) {
      this.locations.push(locationCode);
    }
  }

  addRolCode(rol: string): void {
    const rolCode = this.validationUserFormService.extractRolCode(rol);

    if(rolCode) {
      this.roles.push(rolCode);
    }
  }

  resetControlLocation(): void {
    this.myControlLocations.reset();
  }

  resetControlRol(): void {
    this.myControlRoles.reset();
  }

  removeLocation(index: number) {
    if (this.validationUserFormService.isValidIndex(index, this.locations)) {
      this.locationsFormArray.removeAt(index);
      this.locations.splice(index, 1);
    }
  }

  removeRol(index: number) {
    if (this.validationUserFormService.isValidIndex(index, this.roles)) {
      this.rolesFormArray.removeAt(index);
      this.roles.splice(index, 1);
    }
  }

  /*
  * post user
  */

  submit() {
    if (this.myForm.valid) {
      const user = this.createUserBackendDtoFromForm();
      this.userService.createOne(user).subscribe({
        next: this.handleUserCreationSuccess.bind(this),
        error: (error: string) => this.handleUserCreationError(error)
      });
    }
  }

  createUserBackendDtoFromForm(): UserInfoDto {
    const userDto = new UserDto(
      this.myForm.get('identificationType')?.value,
      this.myForm.get('identificationNumber')?.value,
      this.myForm.get('firstName')?.value,
      this.myForm.get('secondName')?.value,
      this.myForm.get('firstLastName')?.value,
      this.myForm.get('secondLastName')?.value,
      this.myForm.get('username')?.value
    );
    return new UserInfoDto(userDto, this.roles, this.locations);
  }

  handleUserCreationSuccess() {
    const message = this.isUpdatingUser ? 'Usuario actualizado correctamente' : 'Usuario creado correctamente';
    this.openSnackBar(message);
    this.resetForm();
  }

  resetForm(): void {
    this.myForm.reset();
    this.clearFormArray(this.myForm.get('roles') as FormArray);
    this.clearFormArray(this.myForm.get('locations') as FormArray);
    this.resetUserForm();
    this.clearValidationStates(this.myForm);
  }

  clearFormArray(formArray: FormArray): void {
    while (formArray.length) {
      formArray.removeAt(0);
    }
  }

  clearValidationStates(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key);
      if (control != null) {
        if (control instanceof FormGroup) {
          this.clearValidationStates(control);
        } else {
          control.setErrors(null);
        }
      }
    });
  }

  handleUserCreationError(error: string) {
    this.openSnackBar(error);
  }

  /*
  * open snackbar
  */

  openSnackBar(message: string) {
    this._snackBar.open(message, 'Cerrar', {
      duration: 5000
    });
  }

  /*
  * detects changes in inputs
  */

  onDocumentNumberChange(value: string) {
    this.identificationNumber = value;
    this.searchIfReady();
  }

  onDocumentTypeChange() {
    this.myForm.get('identificationType')?.valueChanges.subscribe(value => {
      this.identificationType = value;
      this.searchIfReady();
    });
  }

  /*
  * the user queries whether both data are already present
  */

  searchIfReady() {
    if (!this.autofillingForm && this.identificationType && this.identificationNumber) {
      this.search(this.identificationType, this.identificationNumber);
    }
  }

  search(identificationType: string, identificationNumber: string) {
    this.userService.search(identificationType, identificationNumber).subscribe({
      next: this.handleUserResponse.bind(this),
      error: this.handleError.bind(this)
    });
  }

  handleUserResponse(response: UserInfoDto | null) {
    if (response) {
      this.userInfoDto = response;
      this.isUpdatingUser = true;
      this.autofill();
    } else {
      this.resetUserForm();
    }
  }

  handleError(error: any) {
    console.error(error);
    this.resetUserForm();
  }

  resetUserForm() {
    this.cleanAutofill();
    this.isUpdatingUser = false;
  }

  /*
  * fill in the form data and disable some of them
  */

  autofill() {
    if (this.userInfoDto) {
      this.autofillingForm = true;

      const { user, userRol, userLocations } = this.userInfoDto;
      const formData = {
        firstName: user.firstName,
        firstLastName: user.firstLastName,
        username: user.username,
        secondName: user.secondName,
        secondLastName: user.secondLastName
      };

      this.myForm.patchValue(formData);

      const locationsFormArray = this.myForm.get('locations') as FormArray;
      locationsFormArray.clear();

      const rolesFormArray = this.myForm.get('roles') as FormArray;
      rolesFormArray.clear();

      userLocations.forEach(location => {
        this.addLocationCode(this.validationUserFormService.parseLocationDtoToString(location));
        locationsFormArray.push(this.formBuilder.control(this.validationUserFormService.parseLocationDtoToString(location)));
      });
      userRol.forEach(rol => {
        this.addRolCode(this.validationUserFormService.parseRolDtoToString(rol));
        rolesFormArray.push(this.formBuilder.control(this.validationUserFormService.parseRolDtoToString(rol)));
      });
      this.disableFormFields();
      this.autofillingForm = false;
    }
  }

  disableFormFields() {
    const fieldsToDisable = ['firstName', 'firstLastName', 'username', 'secondName', 'secondLastName'];
    fieldsToDisable.forEach(field => this.myForm.get(field)?.disable());
  }

  cleanAutofill() {
    const fieldsToEnable = ['firstName', 'firstLastName', 'username', 'secondName', 'secondLastName'];

    fieldsToEnable.forEach(field => {
      this.myForm.get(field)?.enable();
    })

    this.myForm.patchValue({
      firstName: '',
      firstLastName: '',
      username: '',
      secondName: '',
      secondLastName: ''
    });

    this.locations.forEach(location => {
      const code = this.validationUserFormService.extractLocationCode(location);
      const number = Number(code);
      this.removeLocation(number);
    });

    this.roles.forEach(rol => {
      const code = this.validationUserFormService.extractRolCode(rol);
      const number = Number(code);
      this.removeRol(number);
    });
  }

  onlyNumbers(event: KeyboardEvent): void {
    let isPassport = false;
    if (this.myForm.get('identificationType')?.value === 'PA') {
      isPassport = true;
      this.validationUserFormService.onlyNumbers(event, isPassport);
    } else {
      this.validationUserFormService.onlyNumbers(event);
    }
  }

  onlyLetters(event: KeyboardEvent): void {
    this.validationUserFormService.onlyLetters(event);
  }

  convertToUpperCase(event: KeyboardEvent, formControlName: string) {
    const input = event.target as HTMLInputElement;
    const upperCaseValue = input.value.toUpperCase();
    this.myForm.get(formControlName)?.setValue(upperCaseValue, { emitEvent: false });
  }

  private createFilteredOptionsObservable(optionType: 'locations' | 'documentTypes' | 'roles', control: FormControl): Observable<string[]> {
    return control.valueChanges.pipe(
      startWith(''),
      switchMap(searchTerm => this.optionsService.filteredOptions(optionType, searchTerm ?? ''))
    );
  }

  private setupFilteredOptions() {
    this.setupFilteredLocationOptions();
    this.setupFilteredDocumentTypeOptions();
    this.setupFilteredRoleOptions();
  }

  private setupFilteredLocationOptions() {
    this.filteredOptionsLocations = this.createFilteredOptionsObservable('locations', this.myControlLocations);
    this.filteredOptionsLocations.subscribe(locations => {
      this.optionsService.updateLocations(locations);
    });
  }

  private setupFilteredDocumentTypeOptions() {
    this.filteredOptionsDocumentTypes = this.createFilteredOptionsObservable('documentTypes', this.myForm.get('identificationType') as FormControl);
  }

  private setupFilteredRoleOptions() {
    this.filteredOptionsRoles = this.createFilteredOptionsObservable('roles', this.myControlRoles);
    this.filteredOptionsRoles.subscribe(roles => {
      this.optionsService.updateRoles(roles);
    })
  }

  get locationsFormArray(): FormArray {
    return this.myForm.get('locations') as FormArray;
  }

  get rolesFormArray(): FormArray {
    return this.myForm.get('roles') as FormArray;
  }
}
