import { Injectable } from '@angular/core';
import { LocationDto } from '../../model/location-dto';
import { OptionsService } from '../options.service';
import { FormArray } from '@angular/forms';
import { RoleDto } from '../../model/role-dto';

@Injectable({
  providedIn: 'root'
})
export class ValidationUserFormService {

  constructor(private readonly optionsService: OptionsService) { }

  parseLocationString(locationString: string): LocationDto | null {
    const regex = /\((\d+)\)\s*(.+)/;
    const matches = locationString.match(regex);
    if (matches && matches.length === 3) {
      const [_, code, name] = matches;
      return new LocationDto(code, name);
    }
    return null;
  }

  parseLocationDtoToString(location: string | LocationDto): string {
    if (typeof location === 'object' && 'id' in location && 'name' in location) {
      return `(${location.id}) ${location.name}`;
    } else if (typeof location === 'string') {
      return location;
    } else {
      return 'Unknown location format';
    }
  }

  parseRolDtoToString(rol: string | RoleDto): string {
    if (typeof rol === 'object' && 'id' in rol && 'name' in rol) {
      return `(${rol.id}) ${rol.name}`;
    } else if (typeof rol === 'string') {
      return rol;
    } else {
      return 'Unknown rol format';
    }
  }

  canAddLocation(location: string, locationsFormArray: FormArray<any>): boolean {
    if (!location) {
      return false;
    }
    const currentLocations = this.optionsService.getCurrentLocations();
    const locationExists = currentLocations.some(loc => loc === location);
    const locationNotInFormArray = !locationsFormArray.value.includes(location);
    return locationExists && locationNotInFormArray;
  }

  canAddRol(rol: string, rolesFormArray: FormArray<any>): boolean {
    if (!rol) {
      return false;
    }
    const currentRoles = this.optionsService.getCurrentRoles();
    const rolExists = currentRoles.some(rl => rl === rol);
    const rolNotInFormArray = !rolesFormArray.value.includes(rol);
    return rolExists && rolNotInFormArray;
  }

  extractLocationCode(location: string): string | null {
    const regex = /\((\d+)\)/;
    const matches = location?.match(regex);
    return matches ? matches[1] : null;
  }

  extractRolCode(rol: string): string | null {
    const regex = /\((\d+)\)/;
    const matches = rol?.match(regex);
    return matches ? matches[1] : null;
  }

  isValidIndex(index: number, array: string[]): boolean {
    return index >= 0 && index < array.length;
  }

  isValidLocation(location: string | null, locationsFormArray: FormArray<any>): boolean {
    return location !== null && this.canAddLocation(location, locationsFormArray);
  }

  isValidRol(rol: string | null, rolesFormArray: FormArray<any>): boolean {
    return rol != null && this.canAddRol(rol, rolesFormArray);
  }

  onlyNumbers(event: KeyboardEvent, isPassport?: boolean): void {
    const target = event.target as HTMLInputElement;
    const fullValue = target.value + event.key;

    if (isPassport) {
      if (!/^[A-Za-z]{0,2}\d*$/.test(fullValue) && event.key !== 'Backspace' && event.key !== 'Tab') {
        event.preventDefault();
      }
    } else {
      // Si no es pasaporte, solo permite números.
      if (!/[0-9]/.test(event.key) && event.key !== 'Backspace' && event.key !== 'Tab') {
        event.preventDefault();
      }
    }
  }

  onlyLetters(event: KeyboardEvent): void {
    if (!/^[a-zA-Z\u00C0-\u00FF]+$/.test(event.key) &&
      event.key !== 'Backspace' &&
      event.key !== 'Tab' &&
      event.key !== 'ArrowLeft' &&
      event.key !== 'ArrowRight' &&
      event.key !== 'Enter' &&
      event.key !== 'Escape') {
      event.preventDefault();
    }
  }
}
