<mat-tab-group animationDuration="0ms">
    <mat-tab label="Gestionar mensajes de error">
        <ng-template matTabContent>
            <app-consumer-message-error-list></app-consumer-message-error-list>
        </ng-template>
    </mat-tab>
    <mat-tab label="Ver solicitudes de autorización">
        <ng-template matTabContent>
            <app-authorization-request-list></app-authorization-request-list>
        </ng-template>
    </mat-tab>
</mat-tab-group>