<!-- top navbar-->
<app-header></app-header>
<div class="body-wrapper" [ngClass]="{'header-menu-active': menuActive}">
      <!-- sidebar -->
      <app-sidebar class="aside"></app-sidebar>
      <!-- Page content-->
      <div class="app-content-wrapper page-content" (click)="this.sidebarService.setStatus(false)">            
            <router-outlet></router-outlet>
      </div>
</div>
