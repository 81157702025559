import { Component } from '@angular/core';

/**
 * Component to render the application footer
 */
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  /**
   * Class constructor
   *
   * @param securityService Security Service
   */
  constructor() {}
}
