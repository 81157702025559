import { convertFormatDate } from 'src/app/shared/function/date-formatter';

export class AuthorizationRequestDto {

    id: number;
    receptionDate: string;
    managementDate: string;
    status: string;
    transactionCode: string;
    businessProcess: string;
    generatingInstitution: string;
    requestingUserIdentificationNumber: string;
    patientIdentificationNumber: string;
    orderType: string;
    hasResponse: boolean;

    constructor(id: number, 
        receptionDate: string, 
        managementDate: string, 
        status: string, 
        transactionCode: string, 
        businessProcess: string, 
        generatingInstitution: string, 
        requestingUserIdentificationNumber: string, 
        patientIdentificationNumber: string, 
        orderType: string,
        hasResponse: boolean) {
        
            this.id = id;
            this.receptionDate = convertFormatDate(receptionDate, false, true);
            this.managementDate = convertFormatDate(managementDate, false, true);
            this.status = status;
            this.transactionCode = transactionCode;
            this.businessProcess = businessProcess;
            this.generatingInstitution = generatingInstitution;
            this.requestingUserIdentificationNumber = requestingUserIdentificationNumber;
            this.patientIdentificationNumber = patientIdentificationNumber;
            this.orderType = this.convertOrderType(orderType);
            this.hasResponse = hasResponse;
    }

    private convertOrderType(orderType: string) {
        if(orderType === '1') {
            return "Medicamentos";
        } else if(orderType === '2') {
            return "Ayudas Dx";
        } else if(orderType === '3') {
            return "Remisiones";
        } else {
            return "";
        }
    }
}
