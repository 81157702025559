import { Injectable } from '@angular/core';
import { LocationService } from './location.service';
import { RoleService } from './role.service';
import { DocumentTypeService } from './document-type.service';
import { BehaviorSubject, Observable, filter, map, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OptionsService {

  private readonly locationsSubject = new BehaviorSubject<string[]>([]);
  private readonly rolesSubject = new BehaviorSubject<string[]>([]);

  constructor(private readonly locationService: LocationService, private readonly roleService: RoleService,
    private readonly documentTypeService: DocumentTypeService) { }

  filteredOptions(optionType: string, searchTerm: string): Observable<string[]> {
    switch (optionType) {
      case 'locations':
        return this.listLocationsOptions(searchTerm);
      case 'documentTypes':
        return this.listDocumentTypesOptions(searchTerm);
      case 'roles':
        return this.listRolesOptions(searchTerm);
      default:
        return of([]);
    }
  }

  listLocationsOptions(filter: string): Observable<string[]> {
    const regexPattern = /^\(\d+\)\s.+/;
    const shouldExtractCode = regexPattern.test(filter);
    const filterValue = shouldExtractCode ? this.extractLocationCode(filter) || '' : filter;
    
    return this.locationService.listAll(filterValue).pipe(
      map(data => data.map(location => `(${location.id}) ${location.name}`))
    );
  }

  listRolesOptions(filter: string): Observable<string[]> {
    return this.roleService.listAll().pipe(
      map(data => data.map(role => `(${role.id}) ${role.name.toString()}`)),
      map(roles => roles.filter(role => role.toLowerCase().includes(filter.toLowerCase())))
    );
  }

  listDocumentTypesOptions(filter: string): Observable<string[]> {
    return this.documentTypeService.listAll().pipe(
      map(data => data.map(identificationType => identificationType.type.toString())),
      map(documentTypes => documentTypes.filter(identificationType => identificationType.toLowerCase()
        .includes(filter.toLowerCase())))
    );
  }

  getCurrentLocations(): string[] {
    return this.locationsSubject.value;
  }

  getCurrentRoles(): string[] {
    return this.rolesSubject.value;
  }

  updateLocations(locations: string[]): void {
    this.locationsSubject.next(locations);
  }

  updateRoles(roles: string[]): void {
    this.rolesSubject.next(roles);
  }

  extractLocationCode(location: string): string | null {
    const regex = /\((\d+)\)/;
    const matches = location?.match(regex);
    return matches ? matches[1] : null;
  }

}
