/**
 * Application constants
 */
export class Constants {

  /** url to obtain user information */
  static securityUserInfo = 'security/user';

  /** url to find out if a user has permission to a resource */
  static securityUserAccess = 'security/access';

/** url to get check if a user has an active session and if not get the html of the login form */
  static securityCheckStatus = 'security/status';

// gen_constants

}
