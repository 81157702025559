<div class="sidebar">
  <mat-accordion class="sidebar-root" multi="true" style="margin-top: 1em;">
    <mat-expansion-panel [expanded]="isMenuExpanded">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Menú
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div *ngFor="let menu of userMenu?.menuItems" class="menu-item">
        <a skipLocationChange class="ripple nav-link" href="#"
          [routerLink]="menu.children && menu.children.length > 0 ? null : '/'.concat(menu.contextPath)"
          (click)="toggleSubmenu(menu)">
          {{ menu.name }}
        </a>
        <app-submenu *ngIf="menu.children && menu.children.length > 0" [basePath]="menu.contextPath"
          [visible]="menu.active" [itemsMenu]="menu.children" [paddingLeft]="20">
        </app-submenu>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>