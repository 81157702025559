import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OrderServiceRequestDetailInfoDto } from '../model/order-service-request-detail-info-dto';
import { ShareService } from 'src/app/shared/service/share-service.service';

@Injectable({
  providedIn: 'root'
})
export class OrderServiceRequestDetailService {

  url = `${environment.api}order-service-request-detail`;

  constructor(protected httpClient: HttpClient, protected shareService: ShareService) { }

  listAll(transactionCode: number): Observable<OrderServiceRequestDetailInfoDto[]> {
    return this.httpClient.get<OrderServiceRequestDetailInfoDto[]>(`${this.url}/${transactionCode}`).pipe(
      catchError(() => {
        this.shareService.changeMessage('Error de servidor al obtener los detalles de las prestaciones');
        return throwError(() => new Error('Error al obtener los detalles de la solicitud de servicio'));
      })
    );
  }
}
