import { Component, Input } from '@angular/core';
import {
  state,
  style,
  trigger,
  transition,
  animate
} from '@angular/animations';
import { SidebarService, MenuItem } from '../../../core/sidebar';

/**
 * Presentation component in charge of rederderizing submenus
 */
@Component({
  selector: 'app-submenu',
  templateUrl: './submenu.component.html',
  animations: [
    trigger('anim', [
      state(
        'false',
        style({
          height: '0',
          padding: '0'
        })
      ),
      state(
        'true',
        style({
          height: '*'
        })
      ),
      transition('false => true', animate('250ms ease-in')),
      transition('true => false', animate('250ms ease-out'))
    ])
  ]
})
export class SubmenuComponent {
  /**
   * items to be rendered
   */

  @Input()
  itemsMenu: Array<any> = [];
  /**
   * indicates whether the submenu should be displayed, this executes the animation
   */
  @Input()
  visible = false;
  /**
   * path of the parent menu, this is used to complete the routing.
   */
  @Input() basePath = '';

  /**
   * value to apply a left padding to a subitem, valid values:
   * 10: apply padding-left 10
   * 20: apply padding-left 20
   * 30: apply padding-left 30
   * 40: apply padding-left 40
   * 50: apply padding-left 50
   */
  @Input() paddingLeft = 10;

  /**
   * Constructor
   *
   * @param sidebarService sidebar service
   */
  constructor(private readonly sidebarService: SidebarService) {}

  /**
   * Changes the active/inactive status of the menuitem passed as parameter
   *
   * @param menu selected menuitem
   */
  toggleSubmenu(menu: MenuItem): void {
    // we deactivate all menus
    const isActive = menu.active;
    this.sidebarService.inactivateAllItems(this.itemsMenu);

    menu.active = !isActive;
  }
}
