import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DocumentTypeDto } from '../model/document-type-dto';

@Injectable({
  providedIn: 'root'
})
export class DocumentTypeService {

  url = `${environment.api}identification-type`;

  constructor(protected httpClient: HttpClient) { }

  listAll(): Observable<DocumentTypeDto[]> {
    return this.httpClient.get<DocumentTypeDto[]>(`${this.url}`);
  }
}
