import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RoleDto } from '../model/role-dto';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  url = `${environment.api}consult-roles`;

  constructor(protected httpClient: HttpClient) { }

  listAll(): Observable<RoleDto[]> {
    return this.httpClient.get<RoleDto[]>(`${this.url}`);
  }
}
