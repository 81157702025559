import { Component, OnInit, OnDestroy } from '@angular/core';
import { SidebarService } from '../core/sidebar/sidebar.service';
import { Subscription } from 'rxjs';

/**
 * Componente padre para la renderización del Layout de la aplicación
 */
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {
  /**
   * Indica si el menú esta visible o no
   */
  public menuActive = false;

  /**
   * Subscription para obtener el estado del sidebar
   */
  private sidebarSuscription?: Subscription = undefined;

  /**
   * Constructor
   *
   * @param sidebarService Servicio sidebar
   */
  constructor(public sidebarService: SidebarService) {}

  /**
   * Inicialización, se carga el estado del sidebar para definir la clase que activa la animación cuando se abre o cierra el menú
   */
  ngOnInit(): void {
    this.sidebarSuscription = this.sidebarService.onStatusChanged(
      (status: boolean) => (this.menuActive = status)
    );
  }

  /**
   * Se libera recursos
   */
  ngOnDestroy(): void {
    if (this.sidebarSuscription) {
      this.sidebarSuscription.unsubscribe();
    }
  }
}
