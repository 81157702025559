import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { DashboardDto } from '../model/dashboard-dto';
import { ShareService } from '../../../../shared/service/share-service.service';
import { MedicalAttentionsFilterDto } from '../model/medical-attentions-filter-dto';

@Injectable({
  providedIn: 'root'
})
export class MedicalAttentionsService {

  url = `${environment.api}show-medical-attentions`;

  constructor(protected httpClient: HttpClient, protected shareService: ShareService) { }

  listAll(requestMessage: MedicalAttentionsFilterDto): Observable<DashboardDto> {
    return this.httpClient.post<DashboardDto>(`${this.url}`, requestMessage);
  }
}
