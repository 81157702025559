export function convertFormatDate(date: string, onlyDate = false, includeSeconds = false) {
    try {        
        const parts = date.split('T');
        if(onlyDate) {
            return parts[0];
        }
        
        if (parts.length < 2) {
            const formatRequired = includeSeconds ? 'YYYY-MM-DDTHH:MM:SS' : 'YYYY-MM-DDTHH:MM';
            throw new Error('Formato de fecha incorrecto, se espera ' + formatRequired);
        }
        
        const datePart = parts[0];
        const timePart = parts[1];

        let [hours, minutes, seconds] = [0,0,0];
        timePart.split(':').forEach((strNum, index) => {
            const num = parseInt(strNum, 10);
            if(index === 0) hours = num;
            if(index === 1) minutes = num;
            if(index === 2) seconds = num;
        });
        
        return `${datePart} ${buildStrTimePart(hours, minutes, seconds, includeSeconds)}`;    
    } catch (error) {
        return date;
    }
}

function buildStrTimePart(hours:number, minutes: number, seconds: number, includeSeconds: boolean) : string {
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours || 12;
    
    const hoursStr = hours < 10 ? '0' + hours.toString() : hours.toString();
    const minutesStr = minutes < 10 ? '0' + minutes.toString() : minutes.toString();
    const secondsStr = !includeSeconds ? '' : (seconds < 10 ? ':0' + seconds.toString() : ':' + seconds.toString());
    
    return `${hoursStr}:${minutesStr}${secondsStr} ${ampm}`;
}

export function getDate(date : Date) : string {
    const day = date.getDate();
    const month = date.getMonth()+1;
    const year = date.getFullYear();
    return getStrDate(day, month, year);
}

export function getStrDate(day: number, month: number, year: number) : string {
    let strDate = day < 10 ? '0'+day : ''+day;
    strDate += '-' + (month < 10 ? '0'+month : ''+month);
    strDate += '-' + year;
    return strDate;
}

export function addMonthsToDate(date : Date, months: number) : Date {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + months);
    return newDate;
}

export function calcNumberOfDaysBetweenDates(date1: Date, date2: Date) : number {
    const differenceInTime = date2.getTime() - date1.getTime();
    return Math.round(differenceInTime / (1000 * 3600 * 24));
}
