import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { AuthorizationRequestDto } from '../../shared/model/authorization-request-dto';
import { ConsultOrderServiceRequestService } from '../../shared/service/consult-order-service-request.service';
import { AuthorizationRequestDetailComponent } from '../authorization-request-detail/authorization-request-detail.component';

@Component({
  selector: 'app-authorization-request-list',
  templateUrl: './authorization-request-list.component.html',
  styleUrls: ['./authorization-request-list.component.scss']
})
export class AuthorizationRequestListComponent implements OnInit {

  myForm: FormGroup;
  displayedColumns: string[] = ['receptionDate', 'managementDate', 'status',
  'transactionCode', 'businessProcess', 'generatingInstitution', 'requestingUserIdentificationNumber', 
  'patientIdentificationNumber', 'orderType', 'details'];
  datasource = new MatTableDataSource<AuthorizationRequestDto>();
  isLoading = false;

  constructor(
    protected consultOrderServiceRequestService: ConsultOrderServiceRequestService,
    private readonly formBuilder: FormBuilder,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.initializeForm();
  }

  initializeForm(): void {
    this.myForm = this.formBuilder.group({
      transactionCode: ['', Validators.required]
    });
  }

  submit() {
    this.datasource.data = [];

    if (this.myForm.valid) {
      this.isLoading = true;
      const transactionCode = this.myForm.get('transactionCode')?.value;
      this.consultOrderServiceRequestService.listAuthorizationRequest(transactionCode).subscribe((data) => {
        this.isLoading = false;
        if (data) {
          this.datasource.data = data.map((item: any) => this.buildAuthorizationRequestDto(item));
        } else {
          this.datasource.data = [];
        }
      });
    }
  }

  buildAuthorizationRequestDto(item: any): AuthorizationRequestDto {
    return new AuthorizationRequestDto(
      item.id,
      item.receptionDate,
      item.managementDate,
      item.status,
      item.transactionCode,
      item.businessProcess,
      item.generatingInstitution,
      item.requestingUserIdentificationNumber,
      item.patientIdentificationNumber,
      item.orderType,
      item.hasResponse
    );
  }

  getStringifyData(data: string) : string {
    let stringifyData = "";
    try {
      stringifyData = JSON.stringify(data, null, "\t");
    } catch (error) {
      stringifyData = data;
    }
    return stringifyData;
  }

  buildDataForJsonMessageComponent(isResponse: boolean, jsonRequestResponse: string) {
    return { 
      data: {
        isResponse,
        jsonRequestResponse
      },
      width: '100%', 
      height: '80%' 
    };
  }

  findJsonRequest(id: number) {
    this.isLoading = true;
    this.consultOrderServiceRequestService.findJsonRequestMessage(id).subscribe((data) => {
      this.isLoading = false;
      if(data) {
        const stringifyData = this.getStringifyData(data);
        this.dialog.open(AuthorizationRequestDetailComponent, this.buildDataForJsonMessageComponent(false, stringifyData));
      }
    });
  }

  findJsonResponse(id: number) {
    this.isLoading = true;
    this.consultOrderServiceRequestService.findJsonResponseMessage(id).subscribe((data) => {
      this.isLoading = false;
      if(data) {
        const stringifyData = this.getStringifyData(data);
        this.dialog.open(AuthorizationRequestDetailComponent, this.buildDataForJsonMessageComponent(true, stringifyData));
      }
    });
  }
}
