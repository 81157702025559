<div *ngIf="!isEmpty">
    <div *ngIf="isLoading" class="loading-overlay">
        <mat-spinner></mat-spinner>
    </div>

    <div *ngIf="!isLoading">
        <br />
        <table mat-table [dataSource]="datasource">
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef class="custom-header-cell" style="color: white;"> ID
                </th>
                <td mat-cell *matCellDef="let element" class="custom-cell"> {{element.id}} </td>
            </ng-container>

            <ng-container matColumnDef="creationDate">
                <th mat-header-cell *matHeaderCellDef class="custom-header-cell" style="color: white;"> FECHA RECEPCIÓN
                </th>
                <td mat-cell *matCellDef="let element" class="custom-cell"> {{element.creationDate}} </td>
            </ng-container>

            <ng-container matColumnDef="processType">
                <th mat-header-cell *matHeaderCellDef class="custom-header-cell" style="color: white;">
                    PROCESO
                </th>
                <td mat-cell *matCellDef="let element" class="custom-cell">{{element.processType}}</td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef class="custom-header-cell" style="color: white;">
                    ESTADO
                </th>
                <td mat-cell *matCellDef="let element" class="custom-cell">{{element.status}}
                </td>
            </ng-container>

            <ng-container matColumnDef="retryDate">
                <th mat-header-cell *matHeaderCellDef class="custom-header-cell" style="color: white;">
                    FECHA REINTENTOS
                </th>
                <td mat-cell *matCellDef="let element" class="custom-cell">{{element.retryDate}}</td>
            </ng-container>

            <ng-container matColumnDef="retryUser">
                <th mat-header-cell *matHeaderCellDef class="custom-header-cell" style="color: white;">
                    USUARIO REINTENTO
                </th>
                <td mat-cell *matCellDef="let element" class="custom-cell">{{element.retryUser}}</td>
            </ng-container>

            <ng-container matColumnDef="details">
                <th mat-header-cell *matHeaderCellDef class="custom-header-cell" style="color: white;">
                 DETALLES
                </th>
                <td mat-cell *matCellDef="let element" class="custom-cell">
                    <button mat-button color="primary" (click)="openDialog(element.id)" matTooltip="Ver detalle del mensaje">
                        <mat-icon>view_list</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSize]="pagingVariables.pageSize" [pageSizeOptions]="[2, 5, 10, 20, 25]"
            [length]="pagingVariables.quantity" showFirstLastButtons (page)="changePage($event)"
            aria-label="Seleccionar los elementos de la tabla">
        </mat-paginator>
    </div>
</div>
<div *ngIf="isEmpty" class="central-container">
    <div >
        No existen mensajes de error sin gestionar.
    </div>
</div>
