import { Component, Inject, OnInit} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConsumerMessageErrorService } from '@components/technical-support/shared/service/consumer-message-error.service';
import { ConsumerMessageErrorDetailDto } from '@components/technical-support/shared/model/consumer-message-error-detail-dto';
import { RetryConsumerMessageDto } from '@components/technical-support/shared/model/retry-consumer-message-dto';
import { AlertConfirmComponent, AlertConfirmDataDTO } from '@components/alert-confirm/alert-confirm.component';

@Component({
  selector: 'app-consumer-message-error-detail',
  templateUrl: './consumer-message-error-detail.component.html',
  styleUrls: ['./consumer-message-error-detail.component.scss']
})
export class ConsumerMessageErrorDetailComponent implements OnInit {
  isLoading = true;
  mensajeDto: ConsumerMessageErrorDetailDto = new ConsumerMessageErrorDetailDto('','','');
  alertConfirmRef: MatDialogRef<AlertConfirmComponent>;
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ConsumerMessageErrorDetailComponent>,
    private readonly service: ConsumerMessageErrorService, 
    private readonly _snackBar: MatSnackBar,
    public dialog: MatDialog) { 
  }

  ngOnInit() {
    this.getDetail();
  }

  getDetail() {
    this.service.getConsumerMessageErrorDetail(this.data.id.toString()).subscribe(data => {
      this.mensajeDto.description = data.description;
      this.mensajeDto.jsonMain = data.jsonMain;
      this.mensajeDto.jsonRetry = data.jsonRetry;      
      this.isLoading = false;
    });
  }

  submit() {
    const alertConfirmData = new AlertConfirmDataDTO(true, true, "Reenviar mensaje", "Desea continuar con la acción?");
    const alertConfirmRef = this.dialog.open(AlertConfirmComponent, { data: alertConfirmData });

    alertConfirmRef.afterClosed().subscribe(result => {
      if(result === 'ok') {
        const messageretry = new RetryConsumerMessageDto(this.data.id, this.mensajeDto.jsonRetry);
        this.service.retryConsumerMessage(messageretry).subscribe({
          next: this.handleRetryMessageErrorSuccess.bind(this),
          error: this.handleRetryMessageErrorError.bind(this)
        });
      }
    });
  }

  handleRetryMessageErrorSuccess() {
    const messageSuccess = 'El mensaje se reenvío correctamente';
    this.openSnackBar(messageSuccess);
    this.dialogRef.close('ok');
  }

  handleRetryMessageErrorError() {
    const messageErro = 'Error al reenviar el mensaje';
    this.openSnackBar(messageErro);
    this.dialogRef.close('error');
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'Cerrar', {
      duration: 5000
    });
  }

  discard() {
    const alertConfirmData = new AlertConfirmDataDTO(true, true, "Descartar mensaje", "Desea continuar con la acción?");
    const alertConfirmRef = this.dialog.open(AlertConfirmComponent, { data: alertConfirmData });

    alertConfirmRef.afterClosed().subscribe(result => {
      if(result === 'ok') {
        this.service.discardConsumerMessageError(this.data.id).subscribe({
          next: this.handleDiscardMessageErrorSuccess.bind(this),
          error: this.handleDiscardMessageErrorError.bind(this)
        });
      }
    });
  }

  handleDiscardMessageErrorSuccess() {
    const message = 'El mensaje se descartó correctamente';
    this.openSnackBar(message);
    this.dialogRef.close('ok');
  }

  handleDiscardMessageErrorError() {
    const message = 'Error al descartar el mensaje';
    this.openSnackBar(message);
    this.dialogRef.close('error');
  }
}
