import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShareService } from '@shared/service/share-service.service';
import { environment } from '@src/environments/environment';
import { Observable } from 'rxjs';
import { ConsumerMessageErrorListDto } from '../model/consumer-message-error-list-dto';
import { ConsumerMessageErrorDetailDto } from '../model/consumer-message-error-detail-dto';
import { RetryConsumerMessageDto } from '../model/retry-consumer-message-dto';

@Injectable({
  providedIn: 'root'
})
export class ConsumerMessageErrorService {

  urlListConsumerMessageError = `${environment.api}list-consumer-message-error`;
  urlConsumerMessageErrorDetail = `${environment.api}consumer-message-error-detail`;
  urlRetryConsumerMessage = `${environment.api}retry-consumer-message`;
  urlDiscardConsumerMessage = `${environment.api}discard-consumer-message-error`;

  constructor(protected httpClient: HttpClient, protected shareService: ShareService) { }

  listConsumerMessageError(pageIndex: number, pageSize: number): Observable<ConsumerMessageErrorListDto> {
    const params = new HttpParams()
      .set('page', pageIndex.toString())
      .set('size', pageSize.toString());

    return this.httpClient.get<ConsumerMessageErrorListDto>(`${this.urlListConsumerMessageError}`, { params });
  }

  getConsumerMessageErrorDetail(id: string): Observable<ConsumerMessageErrorDetailDto> {
    const params = new HttpParams().set('id', id);
    return this.httpClient.get<ConsumerMessageErrorDetailDto>(`${this.urlConsumerMessageErrorDetail}`, { params });
  }

  retryConsumerMessage(requestMessage: RetryConsumerMessageDto): Observable<any> {
    return this.httpClient.post(`${this.urlRetryConsumerMessage}`, requestMessage, { responseType: 'json' });
  }

  discardConsumerMessageError(id: number): Observable<any> {
    const params = new HttpParams().set('id', id);
    return this.httpClient.get(`${this.urlDiscardConsumerMessage}`, { params });
  }
}
