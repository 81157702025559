<div class="input-filter-container">
    <div class="change-doctor-container">
        <app-change-doctor></app-change-doctor>
    </div>
    <div class="range-dates-container">
        <app-date-range-filter></app-date-range-filter>
    </div>
</div>
<mat-tab-group (selectedTabChange)="applyFilter($event)" animationDuration="0ms">
    <mat-tab label="PENDIENTES">
        <app-main-list [filter]="tabLabel" *ngIf="tabLabel === 'pending'"></app-main-list>
    </mat-tab>
    <mat-tab label="RECHAZADOS">
        <app-main-list [filter]="tabLabel" *ngIf="tabLabel === 'rejected'"></app-main-list>
    </mat-tab>
    <mat-tab label="TODO POR MÉDICO">
        <app-main-list [filter]="tabLabel" *ngIf="tabLabel === 'bydoctor'"></app-main-list>
    </mat-tab>
    <mat-tab label="TODO POR SEDE" *ngIf="isDirectorDoctor">
        <app-main-list [filter]="tabLabel" *ngIf="tabLabel === 'bylocation'"></app-main-list>
    </mat-tab>
</mat-tab-group>