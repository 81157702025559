import {Component} from '@angular/core';

@Component({
  selector: 'app-technical-support-main-view',
  templateUrl: './technical-support-main-view.component.html',
  styleUrls: ['./technical-support-main-view.component.scss']
})
export class TechnicalSupportMainViewComponent {

}
