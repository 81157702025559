<div *ngIf="!isEmpty">
    <div *ngIf="isLoading" class="loading-overlay">
        <mat-spinner></mat-spinner>
    </div>

    <div *ngIf="!isLoading">
        <table mat-table [dataSource]="datasource">

            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef class="custom-header-cell" style="color: white;"> FECHA ATENCIÓN
                </th>
                <td mat-cell *matCellDef="let element" class="custom-cell"> {{element.date}} </td>
            </ng-container>

            <ng-container matColumnDef="transactionCode">
                <th mat-header-cell *matHeaderCellDef class="custom-header-cell" style="color: white;"> NUM ORDEN
                </th>
                <td mat-cell *matCellDef="let element" class="custom-cell"> {{element.transactionCode}} </td>
            </ng-container>

            <ng-container matColumnDef="doctor">
                <th mat-header-cell *matHeaderCellDef class="custom-header-cell" style="color: white;">
                    <ng-container *ngIf="filter==='bylocation'">DOCTOR</ng-container>
                </th>
                <td mat-cell *matCellDef="let element" class="custom-cell">
                    <ng-container *ngIf="filter==='bylocation'">
                        ({{element.requestingUserIdentificationType}}{{element.requestingUserIdentificationNumber}})
                        {{element.fullnameUser}}
                    </ng-container>
                </td>
            </ng-container>

            <ng-container matColumnDef="patient">
                <th mat-header-cell *matHeaderCellDef class="custom-header-cell" style="color: white;">
                    PACIENTE
                    <mat-form-field appearance="outline" floatLabel="always"
                        style="margin-left: .2em; background-color: white; border-radius: 4px;" class="wide-form-field">
                        <input matInput placeholder="Buscar paciente" class="custom-mat-input" #searchInputPatient (keyup.enter)="searchPatient(searchInputPatient.value)"
                        (input)="searchPatientOnChange(searchInputPatient.value)">
                        <button mat-icon-button matSuffix (click)="searchPatient(searchInputPatient.value)" color="primary"
                            style="padding: 2px !important;" matTooltip="Botón para buscar paciente">
                            <mat-icon style="font-size: 18px;">search</mat-icon>
                        </button> 
                    </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element" class="custom-cell">
                    ({{element.patientIdentificationType}}{{element.patientIdentificationNumber}})
                    {{element.fullnamePatient}}
                </td>
            </ng-container>

            <ng-container matColumnDef="ips">
                <th mat-header-cell *matHeaderCellDef class="custom-header-cell" style="color: white;">
                    IPS
                    <mat-form-field appearance="outline" floatLabel="always"
                        style="margin-left: .2em; background-color: white; border-radius: 4px;" class="wide-form-field">
                        <input matInput placeholder="Buscar IPS" class="custom-mat-input" #searchInputIps (keyup.enter)="searchIps(searchInputIps.value)"
                        (input)="searchIpsOnChange(searchInputIps.value)">
                        <button mat-icon-button matSuffix (click)="searchIps(searchInputIps.value)" color="primary"
                            style="padding: 2px !important;" matTooltip="Botón para buscar ips">
                            <mat-icon style="font-size: 18px;">search</mat-icon>
                        </button>
                    </mat-form-field>
                </th>
                <td mat-cell *matCellDef="let element" class="custom-cell">
                    ({{element.generatingInstitutionId}}) {{element.generatingInstitutionName}}
                </td>
            </ng-container>

            <ng-container matColumnDef="quantityPending">
                <th mat-header-cell *matHeaderCellDef class="custom-header-cell" style="color: white;">
                    <ng-container *ngIf="filter!=='rejected' && filter!=='pending'">PENDIENTES</ng-container>
                </th>
                <td mat-cell *matCellDef="let element" class="custom-cell"
                    style="text-align: center; padding-right: 1em;">
                    <ng-container
                        *ngIf="filter!=='rejected' && filter!=='pending'">{{element.pendingAuthorizations}}</ng-container>
                </td>
            </ng-container>

            <ng-container matColumnDef="quantityRejected">
                <th mat-header-cell *matHeaderCellDef class="custom-header-cell" style="color: white;">
                    <ng-container *ngIf="filter!=='rejected' && filter!=='pending'">RECHAZADOS</ng-container>
                </th>
                <td mat-cell *matCellDef="let element" class="custom-cell"
                    style="text-align: center; padding-right: 1em;">
                    <ng-container
                        *ngIf="filter!=='rejected' && filter!=='pending'">{{element.rejectedAuthorizations}}</ng-container>
                </td>
            </ng-container>

            <ng-container matColumnDef="total">
                <th mat-header-cell *matHeaderCellDef class="custom-header-cell" style="color: white;">
                    <ng-container *ngIf="filter!=='rejected' && filter!=='pending'">TOTAL PRESTACIONES</ng-container>
                </th>
                <td mat-cell *matCellDef="let element" class="custom-cell"
                    style="text-align: center; padding-right: 1em;">
                    <ng-container
                        *ngIf="filter!=='rejected' && filter!=='pending'">{{element.totalAuthorizations}}</ng-container>
                </td>
            </ng-container>

            <ng-container matColumnDef="totalStateAuthorizations">
                <th mat-header-cell *matHeaderCellDef class="custom-header-cell" style="color: white;">
                    <ng-container *ngIf="filter==='rejected'">PRESTACIONES RECHAZADAS</ng-container>
                    <ng-container *ngIf="filter==='pending'">PRESTACIONES PENDIENTES</ng-container>
                </th>
                <td mat-cell *matCellDef="let element" class="custom-cell"
                    style="text-align: center; padding-right: 1em;">
                    <ng-container
                        *ngIf="filter==='rejected' || filter==='pending'">{{element.totalStateAuthorizations}}</ng-container>
                </td>
            </ng-container>

            <ng-container matColumnDef="details">
                <th mat-header-cell *matHeaderCellDef class="custom-header-cell" style="color: white;">
                    <ng-container>DETALLES</ng-container>
                </th>
                <td mat-cell *matCellDef="let element" class="custom-cell">
                    <ng-container>
                        <button mat-button color="primary" (click)="openDialog(element)" 
                            matTooltip="Ver detalle de las prestaciones">
                            <mat-icon>view_list</mat-icon>
                        </button>
                    </ng-container>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSize]="pagingVariables.pageSize" [pageSizeOptions]="[2, 5, 10, 20, 25]"
            [length]="pagingVariables.quantity" showFirstLastButtons (page)="changePage($event)"
            aria-label="Seleccionar los elementos de la tabla">
        </mat-paginator>
    </div>
</div>
<div *ngIf="isEmpty" class="central-container">
    <div *ngIf="filter === 'pending'">
        No existen atenciones pendientes para el médico seleccionado.
    </div>
    <div *ngIf="filter === 'rejected'">
        No existen atenciones rechazadas para el médico seleccionado.
    </div>
    <div *ngIf="filter === 'bydoctor'">
        No existen atenciones para el médico seleccionado.
    </div>
</div>