<form [formGroup]="dateRangeForm">
    <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Fecha Inicio (DD-MM-YYYY)</mat-label>
        <input type="text" matInput formControlName="filterInitDate" required placeholder="Ingrese la fecha inicio de consulta" 
            (keyup.enter)="selectRangeDates()" (blur)="selectRangeDates()">
        <mat-error *ngIf="dateRangeForm.get('filterInitDate')?.hasError('required')">
            Este campo es obligatorio.
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" floatLabel="always" class="end-date-form-field">
        <mat-label>Fecha Fin (DD-MM-YYYY)</mat-label>
        <input type="text" matInput formControlName="filterEndDate" required placeholder="Ingrese la fecha fin de consulta"
            (keyup.enter)="selectRangeDates()" (blur)="selectRangeDates()">
        <mat-error *ngIf="dateRangeForm.get('filterEndDate')?.hasError('required')">
            Este campo es obligatorio.
        </mat-error>
    </mat-form-field>
</form>