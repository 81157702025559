import { Component, OnInit } from '@angular/core';
import { SidebarService } from '../../core/sidebar/sidebar.service';
import { MenuHeader, MenuItem } from '../../core/sidebar';
import { Router } from '@angular/router';

/**
 * Component for rendering the right side panel of the application.
 */
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  /** user menu */
  userMenu?: MenuHeader = undefined;
  isMenuExpanded = true;

  /**
   * Class constructor
   *
   * @param securityService Security Service
   */
  constructor(private readonly sidebarService: SidebarService, private readonly router: Router) {}

  /**
   * Callback init of the component, here the user menu is loaded.
   */
  ngOnInit(): void {
    this.sidebarService.onMenuLoaded().subscribe((menu) => {
      this.userMenu = menu;
      this.setMenuAsActive(this.router.url);
    });
  }

  /**
   * Changes the active/inactive status of the menuitem passed as parameter
   *
   * @param menu selected menuitem
   */
  toggleSubmenu(menu: MenuItem): void {
    // we deactivate all menus
    const isActive = menu.active;
    this.sidebarService.inactivateAllItems(this.userMenu?.menuItems || []);

    menu.active = !isActive;
  }

  /**
   * Check the items that should be activated from the menu when the page is loaded.
   *
   * @param url router url
   */
  setMenuAsActive(url: string): void {
    if (!this.userMenu) {
      return;
    }

    this.userMenu.menuItems.forEach((item) =>
      this.markIfIsActive(item, '/', url)
    );
  }

  /**
   * recursively marks the active items when the page is refreshed, so that they are selected in the menu.
   *
   * @param item Item to check
   * @param baseUrl base url
   * @param url url complement
   */
  markIfIsActive(item: MenuItem, baseUrl: string, url: string): void {
    const shouldBeMarked =
      baseUrl.concat(item.contextPath) === url ||
      url.indexOf(baseUrl.concat(item.contextPath)) === 0;

    if (item.children && item.children.length > 0) {
      const childBaseUrl = baseUrl.concat(item.contextPath).concat('/');
      item.children.forEach((childItem) =>
        this.markIfIsActive(childItem, childBaseUrl, url)
      );
    }

    item.active = shouldBeMarked;
  }
}
