import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ManagedOrderServiceRequestService {

  url = `${environment.api}managed-order-service-request`;

  constructor(protected httpClient: HttpClient) { }

  markAsManaged(id: number) : Observable<any> {
    const params = new HttpParams()
      .set('id', id.toString())
    return this.httpClient.post(`${this.url}`, {}, { params});
  }
}
