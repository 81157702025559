import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@src/environments/environment';
import { AuthorizationRequestDto } from '../model/authorization-request-dto';
import { ShareService } from '@shared/service/share-service.service';
import { catchError, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConsultOrderServiceRequestService {

  urlListAuthorizationRequest = `${environment.api}list-authorization-request`;
  urlFindJsonRequestMessage = `${environment.api}find-json-request-message`;
  urlFindJsonResponseMessage = `${environment.api}find-json-response-message`;

  constructor(protected httpClient: HttpClient, protected shareService: ShareService) { }

  listAuthorizationRequest(transactionCode: string) {
    const params = new HttpParams().set('transactionCode', transactionCode);
    return this.httpClient.get<AuthorizationRequestDto[]>(this.urlListAuthorizationRequest, { params }).pipe(
      catchError((error) => {
        this.shareService.changeMessage('Error al consultar las solicitudes');
        return [];
      })
    );
  }

  findJsonRequestMessage(authorizationRequestId: number) {
    const params = new HttpParams().set('authorizationRequestId', authorizationRequestId);
    return this.httpClient.get<string>(this.urlFindJsonRequestMessage, { params }).pipe(
      catchError((error) => {
        this.shareService.changeMessage('Error al consultar el mensaje');
        return '';
      })
    );
  }

  findJsonResponseMessage(authorizationRequestId: number) {
    const params = new HttpParams().set('authorizationRequestId', authorizationRequestId);
    return this.httpClient.get<string>(this.urlFindJsonResponseMessage, { params }).pipe(
      catchError((error) => {
        this.shareService.changeMessage('Error al consultar el mensaje');
        return '';
      })
    );
  }
}
