import { AbstractControl, AsyncValidatorFn, ValidationErrors } from "@angular/forms";
import { Observable, catchError, map, of } from "rxjs";
import { OptionsService } from "src/app/layout/user-management/shared/service/options.service";

export function inOptionsValidator(optionsService: OptionsService, optionType: 'locations' | 'documentTypes' | 'roles'): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
        if (!control.value) {
            return of(null);
        }
        return optionsService.filteredOptions(optionType, control.value).pipe(
            map(options => {
                return options.includes(control.value) ? null : { notInOptions: true };
            }),
            catchError(() => of({ notInOptions: true }))
        );
    };
}
