import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { DoctorDto } from '../model/doctor-dto';

@Injectable({
  providedIn: 'root'
})
export class ChangeDoctorService {

  url = `${environment.api}consult-doctors`;

  constructor(protected httpClient: HttpClient) { }

  listAll(): Observable<DoctorDto[]> {
    return this.httpClient.get<DoctorDto[]>(this.url);
  }
}
