import {Component, OnInit} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { addMonthsToDate, calcNumberOfDaysBetweenDates, getDate } from '../../../../shared/function/date-formatter';
import { ShareService } from '../../../../shared/service/share-service.service';

@Component({
  selector: 'app-date-range-filter',
  templateUrl: './date-range-filter.component.html',
  styleUrls: ['./date-range-filter.component.scss'],
})
export class DateRangeFilterComponent implements OnInit {
  
  dateRangeForm: FormGroup;
  filterInitDate = new FormControl();
  filterEndDate = new FormControl();

  constructor(private readonly formBuilder: FormBuilder, private readonly shareService: ShareService) {}

  ngOnInit() {
    this.dateRangeForm = this.formBuilder.group({
      filterInitDate: ['', Validators.pattern(/^(3[01]|[12][0-9]|0?[1-9])(-)(1[0-2]|0?[1-9])\2([0-9]{2})?[0-9]{2}$/)],
      filterEndDate: ['', Validators.pattern(/^(3[01]|[12][0-9]|0?[1-9])(-)(1[0-2]|0?[1-9])\2([0-9]{2})?[0-9]{2}$/)]
    });

    const today = new Date();
    const strFirstDayofMonth = getDate(addMonthsToDate(today, -1));
    const strToday = getDate(today);
    this.dateRangeForm.get('filterInitDate')?.setValue(strFirstDayofMonth);
    this.dateRangeForm.get('filterEndDate')?.setValue(strToday);
    this.shareService.setFilterInitDate(strFirstDayofMonth);
    this.shareService.setFilterEndDate(strToday);
  }

  selectRangeDates() {
    if (this.dateRangeForm.valid) {
      const prevFilterInitDate = this.shareService.getFilterInitDate();
      const prevFilterEndDate = this.shareService.getFilterEndDate();
      const currentFilterInitDate = this.dateRangeForm.get('filterInitDate')?.value;
      const currentFilterEndDate = this.dateRangeForm.get('filterEndDate')?.value;

      if(prevFilterInitDate !== currentFilterInitDate || prevFilterEndDate !== currentFilterEndDate) {
        this.shareService.setFilterInitDate(currentFilterInitDate);
        this.shareService.setFilterEndDate(currentFilterEndDate);
        this.shareService.notifyListRefreshNeeded();
      }
    }
  }
}
