import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-alert-confirm',
  templateUrl: './alert-confirm.component.html'
})
export class AlertConfirmComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: AlertConfirmDataDTO,
    public dialogRef: MatDialogRef<AlertConfirmComponent>) {  }

  onNoClick(): void {
    this.dialogRef.close('cancel');
  }

  onOkClick(): void {
    this.dialogRef.close('ok');
  }
}

export class AlertConfirmDataDTO {
  isConfirm: boolean;
  showTitle: boolean;
  title: string;
  message: string;

  constructor(isConfirm: boolean, showTitle: boolean, title: string, message: string) {
    this.isConfirm = isConfirm;
    this.showTitle = showTitle;
    this.title = title;
    this.message = message;
  }
}
