import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subject, map, startWith } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { ShareService } from '../../../../shared/service/share-service.service';
import { ChangeDoctorService } from '../../shared/service/change-doctor.service';
import { DoctorDto } from '../../shared/model/doctor-dto';

@Component({
  selector: 'app-change-doctor',
  templateUrl: './change-doctor.component.html'
})
export class ChangeDoctorComponent implements OnInit {
  options: DoctorDto[];
  filteredOptions: Observable<DoctorDto[]>;
  myControl = new FormControl();
  private readonly destroy = new Subject<void>();
  doctorSelected = false;

  constructor(private readonly shareService: ShareService, private readonly changeDoctorService: ChangeDoctorService) { }

  ngOnInit() {
    this.initOptions();
    this.setupValueChangeSubscription();
  }
  
  notifyListToUpdate() {
    this.shareService.notifyListRefreshNeeded();
  }
  
  onIdChange(value: string) {
    const selectedDoctor = this.options.find(doctor => doctor.identificationNumber === value);
    if (selectedDoctor) {
      this.shareService.setIdValue(selectedDoctor.identificationNumber);
      this.onDoctorChange(selectedDoctor.identificationNumber);
      this.doctorSelected = true;
    } else {
      this.shareService.clearIdValue();
    }
  }
  
  onDoctorChange(doctorId: string) {
    this.shareService.changeDoctor(doctorId);
  }
  
  displayFn(doctor: DoctorDto | string): string {
    if (!doctor) {
      return '';
    }
    else if (typeof doctor === 'string') {
      return doctor;
    } 
    let fullname = `${doctor.firstName} ${doctor.firstLastName}`;
    if(doctor.secondLastName !== undefined && doctor.secondLastName !== null) {
      fullname = `${fullname} ${doctor.secondLastName}`;
    }
    return fullname;
  }

  initOptions(): void {
    this.changeDoctorService.listAll().pipe(
      takeUntil(this.destroy)
    ).subscribe(data => {
      this.options = data;
      this.initializeFilteredOptions();
    });
  }
  
  initializeFilteredOptions(): void {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      distinctUntilChanged(),
      filter(value => typeof value === 'string' && value.length >= 3),
      map(value => this.filterValue(value)),
      takeUntil(this.destroy)
    );
  }

  setupValueChangeSubscription(): void {
    this.myControl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      takeUntil(this.destroy)
    ).subscribe(value => this.handleValueChange(value));
  }

  handleValueChange(value: any): void {
    if (value === '' && this.doctorSelected) {
      this.onIdChange('');
      this.myControl.setValue(null, { emitEvent: false }); 
      this.initializeFilteredOptions();
      this.notifyListToUpdate();
      this.doctorSelected = false;
    } else if (this.isDoctorDto(value)) {
      this.onIdChange(value.identificationNumber);
    } else {
      this.onIdChange('');
    }
  }

  private isDoctorDto(value: any): value is DoctorDto {
    return value && typeof value === 'object' && 'identificationNumber' in value;
  }

  filterValue(value: string | DoctorDto): DoctorDto[] {
    let filterValueData: string;

    if (typeof value === 'string') {
      filterValueData = value.toLowerCase();
    } else if (typeof value === 'object' && value.firstName && value.firstLastName) {
      filterValueData = `${value.firstName} ${value.firstLastName} ${value.secondLastName || ''}`.toLowerCase();
    }

    return this.options.filter(option => {
      const optionString = `${option.firstName} ${option.firstLastName} ${option.secondLastName}`.toLowerCase();
      return optionString.includes(filterValueData);
    });
  }

  getFullName(doctor: DoctorDto): string{
    return `${doctor.firstName} ${doctor.secondName} ${doctor.firstLastName} ${doctor.secondLastName}`;
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }
}
