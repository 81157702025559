import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable()
export class ShareServiceMessage {

  private readonly transactionCodeSource = new BehaviorSubject<number>(0);
  private readonly messageSource = new BehaviorSubject<string>('');
  private readonly listRefreshNeeded = new Subject<void>();
  currentTransactionCode = this.transactionCodeSource.asObservable();
  currentMessage = this.messageSource.asObservable();
  
  constructor() { }

  changeTransactionCode(transactionCode: number) {
    this.transactionCodeSource.next(transactionCode);
  }

  changeMessage(message: string) {
    this.messageSource.next(message);
  }

  notifyListRefreshNeeded() {
    this.listRefreshNeeded.next();
  }

  getListRefreshNeeded(): Observable<void> {
    return this.listRefreshNeeded.asObservable();
  }
}
