import { convertFormatDate } from "@shared/function/date-formatter";

export class ConsumerMessageErrorDto{
    
    id: number;
    creationDate: string;
    processType: string;
    status: string;
    retryDate: string;
    retryUser: string;

    constructor(id: number, creationDate: string, processType: string,
        state: string, retryDate: string, retryUser: string) {
        this.id = id;
        this.creationDate = convertFormatDate(creationDate, false, true);
        this.processType = processType;
        this.status = state;
        this.retryDate = convertFormatDate(retryDate, false, true);
        this.retryUser = retryUser;
    }
}
