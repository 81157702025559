export class LocationDto {

    id: string;
    name: string;

    constructor(code: string, name: string) {
        this.id = code;
        this.name = name;
    }

    toString(): string {
        return `(${this.id}) ${this.name}`;
    }
}
