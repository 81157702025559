import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
    itemsPerPageLabel = 'Registros por página'; 
    nextPageLabel = 'Siguiente página'; 
    previousPageLabel = 'Página anterior'; 
    firstPageLabel = 'Primera página'; 
    lastPageLabel = 'Última página'; 
}
