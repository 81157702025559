<div class="dialog">
  <div *ngIf="isLoading" class="loading-overlay">
      <mat-spinner></mat-spinner>
  </div>

  <div class="card-order-info">
    <div>Orden N°: {{transactionCode}}</div>
    <div>Fecha Atención: {{dateAttention}}</div>
    <div>Paciente: {{patient}}</div>
    <div>IPS: {{ips}}</div>
  </div>

  <div *ngIf="showNoRecordsToViewMessage" class="no-records-found">
    <h3>No existen prestaciones pendientes por gestionar para esta atención.</h3>
  </div>

  <div *ngFor="let group of orderServiceRequestDetailDS">
    <div class="finalize-button-group">
      <h4>{{group.groupName}}</h4>
      <button mat-raised-button color="primary" *ngIf="group.canFinalize"
              (click)="finalizeGroup(group.idFinalize)">Radicar</button>
      <button mat-button color="primary" *ngIf="group.canMarkAsProcessed"
              (click)="markAsProcessed(group.idFinalize)">Marcar solicitud como procesada</button>
    </div>

    <table mat-table [dataSource]="group.datasource">
      <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef class="custom-header-cell" style="color: white;"> COD. </th>
        <td mat-cell *matCellDef="let element" class="custom-cell"> {{element.code}} </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef class="custom-header-cell" style="color: white;"> NOMBRE </th>
        <td mat-cell *matCellDef="let element" class="custom-cell"> {{element.name}} </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef class="custom-header-cell" style="color: white;"> ESTADO </th>
        <td mat-cell *matCellDef="let element" class="custom-cell">
          <span *ngIf="!isRejectedOrManaged(element.status)">{{element.status}}</span>
          <button *ngIf="isRejectedOrManaged(element.status)" mat-button 
              color="primary" matTooltip="Ver motivo de rechazo"
              (click)="showRejectedMessage(element)">{{element.status}}</button>
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="custom-header-cell"></th>
        <td mat-cell *matCellDef="let element" class="custom-cell">
          <button *ngIf="element.status === REJECTED_STATUS" mat-raised-button class="manage-button"
                  (click)="markAsManaged(element.id, element.idFinalize)">Gestionar</button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

  <mat-dialog-actions class="close-button-container">
    <button mat-raised-button color="primary" (click)="closeDetail()">Salir</button>
  </mat-dialog-actions>
</div>