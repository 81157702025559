export class ConsumerMessageErrorDetailDto{
    description: string;
    jsonMain: string;
    jsonRetry: string;

    constructor(description:string, jsonMain:string, jsonRetry: string){
        this.description = description;
        this.jsonMain = jsonMain;
        this.jsonRetry = jsonRetry;
    }
}
