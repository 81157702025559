import { Component, OnInit, OnDestroy } from '@angular/core';
import { SidebarService } from '../../core/sidebar/sidebar.service';
import { Subscription } from 'rxjs';
import { IdentityService, IdentityUser } from '../../core/security';

/**
 * Component to render the application footer
 */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  /**
   * Indicates whether the menu is visible or not
   */
  menuActive = false;

  /**
   * Indicates whether the menu is visible or not
   */
  userMenuActive = false;

  /**
   * User entity in the system
   */
  user?: IdentityUser = undefined;

  /**
   * Subscription to get the status of the sidebar
   */
  private sidebarSuscription?: Subscription = undefined;

  /**
   * Class constructor
   *
   * @param securityService security service
   */
  constructor(
    private readonly sidebarService: SidebarService,
    private readonly identityService: IdentityService
  ) {}

  /**
   * Initialization, sidebar status and user is loaded.
   */
  ngOnInit(): void {
    this.sidebarSuscription = this.sidebarService.onStatusChanged(
      (status: any) => (this.menuActive = status)
    );
    this.identityService
      .getUserInfo()
      .subscribe((userInfo) => (this.user = userInfo));
  }

  /**
   * Resources are released
   */
  ngOnDestroy(): void {
    if (this.sidebarSuscription) {
      this.sidebarSuscription.unsubscribe();
    }
  }

  /**
   * Change the status of the sidebar, to show or hide it.
   */
  changeSidebarStatus(): void {
    this.menuActive = !this.menuActive;
    this.sidebarService.setStatus(this.menuActive);
  }

  /**
   * logs the user out
   */
  logout(): void {
    this.userMenuActive = false;
    this.identityService.fullLogout();
  }

}
