import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { UserService } from '../../../user-management/shared/service/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';

const MEDICAL_DIRECTOR_ID = "2";

@Component({
  selector: 'app-filters',
  templateUrl: './medical-attention-filters.component.html',
  styleUrls: ['./medical-attention-filters.component.scss'],
})
export class MedicalAttentionFilters implements OnInit {

  @Output() textLabelData = new EventEmitter<string>();
  tabLabel = 'pending';
  isDirectorDoctor:boolean;

  constructor(protected userService : UserService, private readonly _snackBar: MatSnackBar) { }

  ngOnInit() {
    this.userService.listRoles().subscribe({
      next: (data) => {
        if(data.find(role => role.id.toString() === MEDICAL_DIRECTOR_ID) !== undefined) {
          this.isDirectorDoctor = true;
        }
      },
      error: (error) => {
        this._snackBar.open("No se pudo consultar los roles del usuario", 'Cerrar', {
          duration: 5000
        });
      }
    });
  }

  applyFilter(event: MatTabChangeEvent) {
    const tabLabelMap: { [key: number]: string } = {
      0: 'pending',
      1: 'rejected',
      2: 'bydoctor',
      3: 'bylocation'
    };
    this.tabLabel = tabLabelMap[event.index];
    this.emitSelectionData(this.tabLabel);
  }

  emitSelectionData(data: string) {
    this.textLabelData.emit(data);
  }

}
