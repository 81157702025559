<form [formGroup]="myForm" (ngSubmit)="submit()" class="form-container" style="margin-top: 2em;">

    <div class="column">
        <mat-form-field appearance="outline" floatLabel="always" class="wide-form-field">
            <mat-label>Tipo de documento</mat-label>
            <input type="text" placeholder="Escoge un tipo de documento" aria-label="Tipo de documento" matInput
                formControlName="identificationType" required [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let option of filteredOptionsDocumentTypes | async"
                    [value]="option">{{option}}</mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="myForm.get('identificationType')?.hasError('notInOptions')">
                Debes seleccionar un tipo de documento válido de la lista.
            </mat-error>
            <mat-error *ngIf="myForm.get('identificationType')?.hasError('required')">
                Este campo es obligatorio.
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" floatLabel="always" class="wide-form-field">
            <mat-label>Primer nombre</mat-label>
            <input matInput formControlName="firstName" maxlength="20" required (keypress)="onlyLetters($event)"
            (keyup)="convertToUpperCase($event, 'firstName')">
            <mat-error *ngIf="myForm.get('firstName')?.hasError('required')">
                Este campo es obligatorio.
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" floatLabel="always" class="wide-form-field">
            <mat-label>Primer apellido</mat-label>
            <input matInput formControlName="firstLastName" maxlength="20" required (keypress)="onlyLetters($event)"
            (keyup)="convertToUpperCase($event, 'firstLastName')">
            <mat-error *ngIf="myForm.get('firstLastName')?.hasError('required')">
                Este campo es obligatorio.
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" floatLabel="always" class="wide-form-field">
            <mat-label>Username</mat-label>
            <input matInput formControlName="username" maxlength="8" required (keypress)="onlyLetters($event)"
            (keyup)="convertToUpperCase($event, 'username')">
            <mat-error *ngIf="myForm.get('username')?.hasError('required')">
                Este campo es obligatorio.
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" floatLabel="always" class="wide-form-field">
            <mat-label>Sede</mat-label>
            <input type="text" placeholder="Escoge una sede" aria-label="Sede" matInput
                [formControl]="myControlLocations" [matAutocomplete]="autoSites">
            <mat-autocomplete #autoSites="matAutocomplete">
                <mat-option *ngFor="let option of filteredOptionsLocations | async" [value]="option">
                    {{ option }}
                </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="myControlLocations.hasError('inOptions')">
                Debes seleccionar una sede válida de la lista.
            </mat-error>
        </mat-form-field>

        <mat-list role="list">
            <mat-list-item role="listitem" *ngFor="let siteControl of locationsFormArray.controls; let i = index">
                {{ siteControl.value }}
                <button mat-icon-button color="warn" (click)="removeLocation(i)" matTooltip="Botón para eliminar una sede">
                    <mat-icon>delete</mat-icon>
                </button>
            </mat-list-item>
        </mat-list>

        <button mat-raised-button color="accent" class="full-width-button" style="color: #0033A0; max-width: 50%;"
            (click)="addALocation($event)">Agregar sede</button>
    </div>

    <div class="column">
        <mat-form-field appearance="outline" floatLabel="always" class="wide-form-field">
            <mat-label>Número de documento</mat-label>
            <input matInput formControlName="identificationNumber" minlength="6" maxlength="10" required
                (keypress)="onlyNumbers($event)" (input)="onDocumentNumberChange($any($event.target).value)"
                (keyup)="convertToUpperCase($event, 'identificationNumber')">
            <mat-error *ngIf="myForm.get('identificationNumber')?.hasError('required')">
                Este campo es obligatorio.
            </mat-error>
            <mat-error *ngIf="myForm.get('identificationNumber')?.hasError('minlength')">
                El número de documento debe tener al menos 6 caracteres.
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" floatLabel="always" class="wide-form-field">
            <mat-label>Segundo nombre</mat-label>
            <input matInput formControlName="secondName" maxlength="20" (keypress)="onlyLetters($event)"
            (keyup)="convertToUpperCase($event, 'secondName')">
        </mat-form-field>

        <mat-form-field appearance="outline" floatLabel="always" class="wide-form-field">
            <mat-label>Segundo apellido</mat-label>
            <input matInput formControlName="secondLastName" maxlength="20" required (keypress)="onlyLetters($event)"
            (keyup)="convertToUpperCase($event, 'secondLastName')">
            <mat-error *ngIf="myForm.get('secondLastName')?.hasError('required')">
                Este campo es obligatorio.
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" floatLabel="always" class="wide-form-field">
            <mat-label>Rol</mat-label>
            <input type="text" placeholder="Escoge un rol" aria-label="Rol" matInput
                [formControl]="myControlRoles" [matAutocomplete]="autoRoles">
            <mat-autocomplete #autoRoles="matAutocomplete">
                <mat-option *ngFor="let option of filteredOptionsRoles | async" [value]="option">
                    {{ option }}
                </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="myControlRoles.hasError('inOptions')">
                Debes seleccionar un rol válido de la lista.
            </mat-error>
        </mat-form-field>

        <mat-list roles="list">
            <mat-list-item roles="listitem" *ngFor="let siteControl of rolesFormArray.controls; let i = index">
                {{ siteControl.value }}
                <button mat-icon-button color="warn" (click)="removeRol(i)" matTooltip="Botón para eliminar un rol">
                    <mat-icon>delete</mat-icon>
                </button>
            </mat-list-item>
        </mat-list>

        <button mat-raised-button color="accent" class="full-width-button" style="color: #0033A0; max-width: 50%;"
            (click)="addRol($event)">Agregar rol</button>

    </div>

    <div class="submit-button-container">
        <button mat-raised-button color="primary" type="submit" [disabled]="!myForm.valid || locations.length === 0">
            {{ isUpdatingUser ? 'Actualizar' : 'Guardar' }}
        </button>
    </div>
</form>