import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable()
export class ShareService {

  private readonly messageSource = new BehaviorSubject<string>('');
  private readonly doctorSource = new BehaviorSubject<string | null>(null);
  private doctorId: string;
  private readonly listRefreshNeeded = new Subject<void>();
  currentMessage = this.messageSource.asObservable();
  currentDoctor = this.doctorSource.asObservable();
  private filterInitDate: string;
  private filterEndDate: string;
  
  constructor() { }

  changeMessage(message: string) {
    this.messageSource.next(message);
  }

  changeDoctor(doctorId: string) {
    this.doctorSource.next(doctorId);
  }

  clearIdValue() {
    this.doctorId = '';
  }

  getIdValue(): string {
    return this.doctorId;
  }

  setIdValue(value: string) {
    this.doctorId = value;
  }

  notifyListRefreshNeeded() {
    this.listRefreshNeeded.next();
  }

  getListRefreshNeeded(): Observable<void> {
    return this.listRefreshNeeded.asObservable();
  }

  getFilterInitDate(): string {
    return this.filterInitDate;
  }

  getFilterEndDate(): string {
    return this.filterEndDate;
  }

  setFilterInitDate(value: string) {
    this.filterInitDate = value;
  }

  setFilterEndDate(value: string) {
    this.filterEndDate = value;
  }
}
