import { convertFormatDate } from "../../../../shared/function/date-formatter";

export class MedicalAttentionDto {

    date: string;
    transactionCode: string;
    requestingUserIdentificationType: string;
    requestingUserIdentificationNumber: string;
    fullnameUser: string;
    patientIdentificationType: string;
    patientIdentificationNumber: string;
    fullnamePatient: string;
    generatingInstitutionId: string;
    generatingInstitutionName: string;
    pendingAuthorizations: number;
    rejectedAuthorizations: number;
    totalAuthorizations: number;
    totalStateAuthorizations: number;

    constructor(date: string, transactionCode: string, requestingUserIdentificationType: string, 
        requestingUserIdentificationNumber: string, fullnameUser: string, patientIdentificationType: string, 
        patientIdentificationNumber: string, fullnamePatient: string, generatingInstitutionId: string, 
        generatingInstitutionName: string, pendingAuthorizations: number, rejectedAuthorizations: number, 
        totalAuthorizations: number, totalStateAuthorizations: number) {
            this.date = convertFormatDate(date, true);
            this.transactionCode = transactionCode;
            this.requestingUserIdentificationType = requestingUserIdentificationType;
            this.requestingUserIdentificationNumber = requestingUserIdentificationNumber;
            this.fullnameUser = fullnameUser;
            this.patientIdentificationType = patientIdentificationType;
            this.patientIdentificationNumber = patientIdentificationNumber;
            this.fullnamePatient = fullnamePatient;
            this.generatingInstitutionId = generatingInstitutionId;
            this.generatingInstitutionName = generatingInstitutionName;
            this.pendingAuthorizations = pendingAuthorizations;
            this.rejectedAuthorizations = rejectedAuthorizations;
            this.totalAuthorizations = totalAuthorizations;
            this.totalStateAuthorizations = totalStateAuthorizations;
    }
}
