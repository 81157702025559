import { Component, OnInit } from '@angular/core';
import { RedirectService } from './shared/service/redirect.service';

/** allows the use of the jquery wildcard $ in any application component */
declare const $: any;

/**
 * Initial component of the application, here the router-outlet is loaded.
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  constructor(private readonly redirectService: RedirectService) {}
  /**
   * Callback init of the component, used to disable the default behavior of html elements. &lt;a&gt;&lt;/a&gt;
   * when you click on one of these.
   */
  ngOnInit(): void {
    $(document).on('click', '[href="#"]', (e: MouseEvent) =>
      e.preventDefault()
    );

    this.redirectService.redirectToFirstAccessiblePage();
  }
}
