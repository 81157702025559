<form class="form-container">
    <mat-form-field appearance="outline" floatLabel="always" style="width: max-content;">
        <mat-label>Seleccionar médico</mat-label>
        <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                {{getFullName(option)}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</form>