import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable, AsyncSubject } from 'rxjs';
import { share } from 'rxjs/operators';

import { IdentityUser } from './identity-user';
import { MenuHeader } from '../sidebar/menu-header';
import { Constants } from '../utils/constants';
import { environment } from '../../../environments/environment';

/**
 *  Service used to manage user security
 */
@Injectable({
  providedIn: 'root'
})
export class IdentityService {
  /** represents the user logged into the system */
  private user?: IdentityUser = undefined;

  /** Subject to notify user information  */
  private readonly authState = new Subject<IdentityUser>();

  /**
   *  Stores a reference to the observable that gets the information from the user and marks it as a share type so that 
   *  the same request is not made multiple times. the same request is not made multiple times.
   */
  private observerLoginInfo?: Observable<any> = undefined;

  /**
   * Class constructor
   *
   * @param HttpClient service to make http requests
   */
  constructor(private readonly http: HttpClient) { }

  /**
   * This function is responsible for sending through the authState the user,
   * either retrieved from the sessionStorage or from the service exposed in the backend.
   */
  refreshUserInfo(): void {
    this.observerLoginInfo = this.http
      .get(environment.api + Constants.securityUserInfo)

      .pipe(share({ connector: () => new AsyncSubject(), resetOnError: false, resetOnComplete: false, resetOnRefCountZero: false }));

    this.observerLoginInfo.subscribe((user) => {
      this.user = user;
      if (this.user) {
        const lastNumberAccess = +this.user.lastAccess;
        const lastDateAccess = new Date(lastNumberAccess);
        this.user.lastAccess = this.formatDateToString(lastDateAccess);
        user.menu = (JSON.parse(user.menu)[0]) as MenuHeader;
        this.authState.next(user);
      }
    });
  }

  formatDateToString(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  /**
   * Returns an Observable on which the user will be notified in the session.
   *
   * @returns returns a stream where the information of the logged in user can be obtained.
   */
  getUserInfo(): Observable<IdentityUser> {
    setTimeout(() => {
      this.refreshUserInfo();
    }, 500);

    return this.authState.asObservable();
  }

  /**
   * Returns the menu associated with the user
   *
   * @returns returns the user menu
   */
  getUserMenu(): Promise<MenuHeader> {
    return new Promise((resolver) => {
      if (this.user != null) {
        resolver(this.user.menu);
      } else {
        this.getUserInfo().subscribe((user) => {
          resolver(user.menu);
        });
      }
    });
  }

  /**
   * With this function you can obtain the LoggedInYet value or the login form sent by the security system.
   *
   * @returns returns the html representing the login form
   */
  getLoginForm(): Observable<string> {
    const options = { responseType: 'text' as 'text' };
    return this.http.post(environment.api + Constants.securityCheckStatus, null, options);

  }

  /**
   * This function checks if a user is logged into the system.
   *
   * @returns indicates whether the user is logged in to the system or not
   */
  isLoggedIn(): boolean {
    return this.user != null;
  }

  /**
   * With this function you can check if the user in the session has permission to a specific resource.
   *
   * @param resource resource url
   * @returns Indicates whether a user has access to a specific resource.
   */
  isAuthorized(resource: string): Observable<boolean> {
    const body = { "method": "GET", "path": resource };
    return this.http.post<boolean>(environment.api + Constants.securityUserAccess, body);

  }

  /**
   * This function performs a partial system logout, it does not logout.
   */
  partialLogout(): void {
    window.location.href = '/logout';
  }

  /**
   * This function invokes the complete logout of the system, 
   * here if the session is closed and the user should enter his credentials if he wants to log in again.
   */
  fullLogout(): void {
    const urlLogout = this.user?.fullUrlLogout;
    // it is necessary to clean the sessionStorage
    this.clearObserverForLogin();
    window.location.href = urlLogout || '';
  }

  /**
   * This function is responsible for removing the reference of the Observable
   * that returns the information of the logged in user so that the information 
   * can be refreshed by requesting it to the backend.
   */
  clearObserverForLogin(): void {
    this.observerLoginInfo = undefined;
    this.user = undefined;
  }
}
