import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FinalizeManagedOrderServiceRequestService {

  urlFinalize = `${environment.api}finalize-managed-order-service-request`;
  urlMarkAsProcessed = `${environment.api}mark-order-service-request-processed`;

  constructor(protected httpClient: HttpClient) { }

  finalizeManaged(idFinalize: number) : Observable<any> {
    return this.httpClient.put(`${this.urlFinalize}/${idFinalize}`, {});
  }

  markAsProcessed(idFinalize: number) : Observable<any> {
    return this.httpClient.put(`${this.urlMarkAsProcessed}/${idFinalize}`, {});
  }
}
