import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-authorization-request-detail',
  templateUrl: './authorization-request-detail.component.html',
  styleUrls: ['./authorization-request-detail.component.scss']
})
export class AuthorizationRequestDetailComponent {
  
  constructor(
    @Optional() public dialogRef: MatDialogRef<AuthorizationRequestDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
