import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LayoutComponent } from './layout.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SubmenuComponent } from './sidebar/submenu/submenu.component';
import { PagingVariables } from './dashboard/shared/global-variables/paging-variables';
import { MedicalAttentionListComponent } from './dashboard/components/medical-attention-list/medical-attention-list.component';
import { MedicalAttentionFilters } from './dashboard/components/medical-attention-filters/medical-attention-filters.component';
import { OrderServiceRequestDetailComponent } from './dashboard/components/order-service-request-detail/order-service-request-detail.component';
import { ChangeDoctorComponent } from './dashboard/components/change-doctor/change-doctor.component';
import { UserFormComponent } from './user-management/components/user-form/user-form.component';
import { MassiveUserFormComponent } from './user-management/components/massive-user-form/massive-user-form.component';
import { MainPageComponent } from './main-page/main-page.component';
import { ConsumerMessageErrorListComponent } from './technical-support/components/consumer-message-error-list/consumer-message-error-list.component';
import { AuthorizationRequestListComponent } from './technical-support/components/authorization-request-list/authorization-request-list.component';
import { AuthorizationRequestDetailComponent } from './technical-support/components/authorization-request-detail/authorization-request-detail.component';
import { TechnicalSupportMainViewComponent } from './technical-support/components/technical-support-main-view/technical-support-main-view.component';
import { ConsumerMessageErrorDetailComponent } from './technical-support/components/consumer-message-error-detail/consumer-message-error-detail.component';
import { AlertConfirmComponent } from './alert-confirm/alert-confirm.component';
import { DateRangeFilterComponent } from './dashboard/components/date-range-filter/date-range-filter.component';
/**
 * Module that includes all the components in charge of rendering the layout in the application tree.
 */

@NgModule({
  imports: [
    SharedModule, ReactiveFormsModule
  ],
  declarations: [
    LayoutComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    SubmenuComponent, 
    MedicalAttentionFilters, 
    MedicalAttentionListComponent,
    OrderServiceRequestDetailComponent,
    ChangeDoctorComponent,
    UserFormComponent,
    MassiveUserFormComponent,
    MainPageComponent,
    ConsumerMessageErrorListComponent,
    ConsumerMessageErrorDetailComponent,
    AuthorizationRequestListComponent,
    AuthorizationRequestDetailComponent,
    TechnicalSupportMainViewComponent,
    AlertConfirmComponent,
    DateRangeFilterComponent
  ],
  exports: [LayoutComponent, HeaderComponent, SidebarComponent, FooterComponent], 
  providers: [ PagingVariables ]
})
export class LayoutModule {}
