import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';

import { MedicalAttentionDto } from '../../shared/model/medical-attentions-dto';
import { MedicalAttentionsService } from '../../shared/service/medical-attentions.service';
import { DashboardDto } from '../../shared/model/dashboard-dto';
import { PagingVariables } from '../../shared/global-variables/paging-variables';
import { OrderServiceRequestDetailComponent } from '../order-service-request-detail/order-service-request-detail.component';
import { ShareService } from '../../../../shared/service/share-service.service';
import { MedicalAttentionsFilterDto } from '../../shared/model/medical-attentions-filter-dto';

@Component({
  selector: 'app-main-list',
  templateUrl: './medical-attention-list.component.html',
  styleUrls: ['./medical-attention-list.component.scss']
})
export class MedicalAttentionListComponent implements OnInit, OnChanges, OnDestroy {

  @Input() filter: string;
  isLoading = true;
  isEmpty = false;

  displayedColumns: string[] = ['date', 'transactionCode', 'doctor', 'patient', 'ips',
    'quantityPending', 'quantityRejected', 'total', 'totalStateAuthorizations', 'details'];
  datasource = new MatTableDataSource<MedicalAttentionDto>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  private readonly subscriptions = new Subscription();
  patientFilter: string;
  ipsFilter: string;

  constructor(protected service: MedicalAttentionsService, public pagingVariables: PagingVariables,
    public dialog: MatDialog, protected shareService: ShareService) { }

  ngOnInit() {
    const doctorChangeSubscription = this.shareService.currentDoctor.subscribe(() => {
      this.isLoading = true;
      this.listAll();
    });

    const listRefreshSubscription = this.shareService.getListRefreshNeeded().subscribe(() => {
      this.isLoading = true;
      this.isEmpty = false;
      this.listAll();
    });

    this.subscriptions.add(doctorChangeSubscription);
    this.subscriptions.add(listRefreshSubscription);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filter && changes.filter.currentValue !== changes.filter.previousValue) {
      this.resetPagination();
      this.isLoading = true;
    }
  }

  changePage(e: any) {
    this.pagingVariables.pageIndex = e.pageIndex;
    this.pagingVariables.pageSize = e.pageSize;
    this.listAll();
  }

  resetPagination() {
    const defaultPageSize = 20;
    this.pagingVariables.pageIndex = 0;
    this.pagingVariables.pageSize = defaultPageSize;

    if (this.paginator) {
      this.paginator.pageIndex = 0;
      this.paginator.pageSize = defaultPageSize;
    }
  }

  getFormatedFilterDate(filterDate : string) : string {
    if(filterDate !== null && filterDate !== undefined && filterDate.trim().length === 10) {
      const arrDate = filterDate.trim().split("-");
      return `${arrDate[2]}-${arrDate[1]}-${arrDate[0]}`;
    }
    return "";
  }

  listAll(): Observable<DashboardDto> {
    this.datasource.data = [];
    let observable: Observable<DashboardDto>;
    const initDate = this.getFormatedFilterDate(this.shareService.getFilterInitDate()) + "T00:00:00";
    const endDate = this.getFormatedFilterDate(this.shareService.getFilterEndDate()) + "T23:59:59";

    const params = new MedicalAttentionsFilterDto(this.filter, this.shareService.getIdValue(), 
      this.patientFilter, this.ipsFilter, this.pagingVariables.pageIndex, this.pagingVariables.pageSize, 
      initDate, endDate);
    
    observable = this.service.listAll(params);
    observable.subscribe(data => {
      const listWithContent = Object.values(data).find(list => Array.isArray(list) && list.length > 0);
      if (listWithContent) {
        this.datasource.data = listWithContent.map((item: any) => this.buildMedicalAttentionDto(item));
      } else {
        this.datasource.data = [];
      }
      this.pagingVariables.quantity = data.totalRecords;
      this.isLoading = false;
      this.isEmpty = this.datasource.data.length === 0 && !this.patientFilter && !this.ipsFilter;
    });
    return observable;
  }

  openDialog(medicalAttention: MedicalAttentionDto) {
    const patient = `(${medicalAttention.patientIdentificationType}${medicalAttention.patientIdentificationNumber})${medicalAttention.fullnamePatient}`;
    const ips = `(${medicalAttention.generatingInstitutionId})${medicalAttention.generatingInstitutionName}`;
    const dateAttention = medicalAttention.date;
    

    const dialogRef = this.dialog.open(OrderServiceRequestDetailComponent, { 
      data: {
        transactionCode: medicalAttention.transactionCode,
        patient,
        ips,
        dateAttention
      }, 
      width: '100%', 
      height: '80%',
      disableClose: true 
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result === "reload") {
        this.isLoading = true;
        this.listAll();
      }
    });
  }

  searchPatient(searchInput: string) {
    this.patientFilter = searchInput;
    this.listAll();
  }

  searchIps(searchInput: string) {
    this.ipsFilter = searchInput;
    this.listAll();
  }

  searchPatientOnChange(searchInput: string) {
    if (searchInput.trim() === '') {
      this.searchPatient(searchInput);
    }
  }

  searchIpsOnChange(searchInput: string) {
    if (searchInput.trim() === '') {
      this.searchIps(searchInput);
    }
  }

  buildMedicalAttentionDto(item: any): MedicalAttentionDto {
    return new MedicalAttentionDto(
      item.date,
      item.transactionCode,
      item.requestingUserIdentificationType,
      item.requestingUserIdentificationNumber,
      item.fullnameUser,
      item.patientIdentificationType,
      item.patientIdentificationNumber,
      item.fullnamePatient,
      item.generatingInstitutionId,
      item.generatingInstitutionName,
      item.pendingAuthorizations,
      item.rejectedAuthorizations,
      item.totalAuthorizations,
      item.totalStateAuthorizations
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
